import React from 'react';
import wearYourEyeglasses from '../../assets/svg/instructions/wear-your-eyeglasses.svg';

interface Props {

}

const WearYourEyeGlassesInstruction : React.FC<Props>  = () =>  {

    return (
      <>
            <div className='instruction__image'>
                <img src={wearYourEyeglasses} alt="mobile-distance-instruction" />
            </div>

            <div className='breadcrumb__container'>
                <div className='breadcrumb'></div>
                <div className='breadcrumb--selected'></div>
            </div>

            <div className='instruction__title'>
                <h2>Wear Your Glasses</h2>
            </div>

            <div className='instruction__description'>
                <caption>If you have glasses for distance vision or progressive lenses, keep them on. If you wear progressive lenses, lift your head in order to look through the lower part of your lenses.</caption>
            </div>
        </>
    )
}

export default WearYourEyeGlassesInstruction;