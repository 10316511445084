import React from 'react';

import avatarGirl1NoGlasses from '../../assets/svg/avatar/avatar-girl-01-no-glasses.svg';
import avatarGirl2NoGlasses from '../../assets/svg/avatar/avatar-girl-02-no-glasses.svg';
import avatarGirl3NoGlasses from '../../assets/svg/avatar/avatar-girl-03-no-glasses.svg';
import avatarBoy1NoGlasses from '../../assets/svg/avatar/avatar-boy-01-no-glasses.svg';
import avatarBoy2NoGlasses from '../../assets/svg/avatar/avatar-boy-02-no-glasses.svg';
import avatarBoy3NoGlasses from '../../assets/svg/avatar/avatar-boy-03-no-glasses.svg';
import avatarGirl1WithGlasses from '../../assets/svg/avatar/avatar-girl-01-with-glasses.svg';
import avatarGirl2WithGlasses from '../../assets/svg/avatar/avatar-girl-02-with-glasses.svg';
import avatarGirl3WithGlasses from '../../assets/svg/avatar/avatar-girl-03-with-glasses.svg';
import avatarBoy1WithGlasses from '../../assets/svg/avatar/avatar-boy-01-with-glasses.svg';
import avatarBoy2WithGlasses from '../../assets/svg/avatar/avatar-boy-02-with-glasses.svg';
import avatarBoy3WithGlasses from '../../assets/svg/avatar/avatar-boy-03-with-glasses.svg';

interface Props {
    avatar:any,
}

const Avatar : React.FC<Props> = ({
    avatar
}) => {
    
    return (
        <div className="avatar">
            { avatar === 1 &&  <img src={avatarGirl1WithGlasses} alt="avatar-girl-01" /> }
            { avatar === 2 &&  <img src={avatarGirl1NoGlasses} alt="avatar-girl-01" /> }
            
            { avatar === 3 &&  <img src={avatarGirl2WithGlasses} alt="avatar-girl-02" /> }
            { avatar === 4 &&  <img src={avatarGirl2NoGlasses} alt="avatar-girl-02" /> }

            { avatar === 5 &&  <img src={avatarGirl3WithGlasses} alt="avatar-girl-03" /> }
            { avatar === 6 &&  <img src={avatarGirl3NoGlasses} alt="avatar-girl-03" /> }

            { avatar === 7 &&  <img src={avatarBoy1WithGlasses} alt="avatar-boy-01" /> }
            { avatar === 8 &&  <img src={avatarBoy1NoGlasses} alt="avatar-boy-01" /> }
            
            { avatar === 9 &&  <img src={avatarBoy2WithGlasses} alt="avatar-boy-02" /> }
            { avatar === 10 &&  <img src={avatarBoy2NoGlasses} alt="avatar-boy-02" /> }

            { avatar === 11 &&  <img src={avatarBoy3WithGlasses} alt="avatar-boy-02" /> }
            { avatar === 12 &&  <img src={avatarBoy3NoGlasses} alt="avatar-boy-03" /> }
        </div>
    )
}

export default Avatar;