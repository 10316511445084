import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { getBadges } from '../../store/slices/badges-slice';
import Badge from '../../components/badge/Badge';
import Navigation from '../../components/navigation/Navigation';

import backIcon from '../../assets/svg/icons/arrow-back-white.svg';

interface Props {

}

const MyBadges : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM(routes.mybadges.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { welcomeBadge,
            profileBadge,
            goodEyeSightBadge,
            glassesBadge,
            avatarBadge,
            storyBadge,
            eyeTestBadge,
            eyeAmbassadorBadge,
            eyeTestChampBadge,
            charityClinicSearcherBadge,
            eyeTipsChampBadge,
            upComingEventsAdvocateBadge } = useSelector(getBadges);


    // HOOKS
    useEffect(() => {
        connect();
    }, []);

    //METHODS
    const goToDashboard = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.mybadges, 
                routes.dashboard,
                'SLIDE_RIGHT',
                500
            );
        }, 250);
    }
    return(
        <div className={B() + ' page bg-light'}>
            <div className="flex-col">
                <div className="flex-row title-wrapper"> 
                    <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                        
                    <h1>My Badges</h1>
                </div>

                <div className={E("badges__grid")}>
                    { welcomeBadge ? 
                        <div>
                        <Badge name="welcome-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="welcome-badge-muted" />
                        </div>
                    }

                    { profileBadge ? 
                        <div>
                        <Badge name="profile-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="profile-badge-muted" />
                        </div>
                    }

                    { goodEyeSightBadge ? 
                        <div>
                        <Badge name="good-eyesight-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="good-eyesight-badge-muted" />
                        </div>
                    }

                    { glassesBadge ? 
                        <div>
                        <Badge name="glasses-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="glasses-badge-muted" />
                        </div>
                    }

                    { avatarBadge ? 
                        <div>
                        <Badge name="avatar-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="avatar-badge-muted" />
                        </div>
                    }             

                    { storyBadge ? 
                        <div>
                        <Badge name="story-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="story-badge-muted" />
                        </div>
                    } 

                    { eyeTestBadge ? 
                        <div>
                        <Badge name="eye-test-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="eye-test-badge-muted" />
                        </div>
                    }

                    { eyeAmbassadorBadge ? 
                        <div>
                        <Badge name="eye-ambassador-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="eye-ambassador-badge-muted" />
                        </div>
                    }

                    { eyeTestChampBadge ? 
                        <div>
                        <Badge name="eye-test-champ-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="eye-test-champ-badge-muted" />
                        </div>
                    }

                    { charityClinicSearcherBadge ? 
                        <div>
                        <Badge name="charity-clinic-searcher-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="charity-clinic-searcher-badge-muted" />
                        </div>
                    }

                    { eyeTipsChampBadge ? 
                        <div>
                        <Badge name="eye-tips-champ-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="eye-tips-champ-badge-muted" />
                        </div>
                    }

                    { upComingEventsAdvocateBadge ? 
                        <div>
                        <Badge name="charity-clinic-advocate-badge" />
                        </div>
                        : 
                        <div className={E("badge--muted")}>
                        <Badge name="charity-clinic-advocate-badge-muted" />
                        </div>
                    }
                </div>

                <div>
                <Navigation page={routes.mybadges.id} pageFrom={routes.mybadges}/>
                </div>
            </div>

           
        </div>
    )

}

export default withAnimationTransition(MyBadges, {
    entrance: 'FADE_IN'
});
