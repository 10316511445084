import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';

import store from './store/store';
import routes from './constants/routes.constants';

import SplashScreen from './pages/splash-screen/SplashScreen';
import UserInfo from './pages/user-info/UserInfo';
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/profile/Profile';
import MyBadges from './pages/my-badges/MyBadges';
import EyeCareTips from './pages/eyecare-tips/EyeCareTips';
import ResultScreen from './pages/result-screen/ResultScreen';
import Story from './pages/story/Story';
import SelectMode from './pages/select-mode/SelectMode';
import ExplorerMode from './pages/explorer-mode/ExplorerMode';
import DistanceTest from './pages/distance-test/DistanceTest';
import DistanceTestInstructions from './pages/distance-test/DistanceTestInstructions';
import AstigmatismTestInstructions from './pages/astigmatism-test/AstigmatismTestInstructions';
import AstigmatismTest from './pages/astigmatism-test/AstigmatismTest';
import NearVisionTestInstructions from './pages/nearvision-test/NearVisionTestInstructions';
import NearVisionTest from './pages/nearvision-test/NearVisionTest';
import ColorVisionTestInstructions from './pages/colorvision-test/ColorVisionTestInstructions';
import ColorVisionTest from './pages/colorvision-test/ColorVisionTest';
import LightSensitivityTestInstructions from './pages/lightsensitivity-test/LightSensitivityTestInstructions';
import LightSensitivityTest from './pages/lightsensitivity-test/LightSensitivityTest';
import MyResults from './pages/my-results/MyResults';

function App() {
  return (
    <div className="App">
      <Provider store={store} >
        <Router>

          <Switch> 
            <Route path={routes.splashscreen.path} exact component={SplashScreen}/>  
            <Route path={routes.userinfo.path} exact component={UserInfo}/>
            <Route path={routes.userCode.path} exact component={UserInfo}/>
            <Route path={routes.dashboard.path} exact component={Dashboard}/> 
            <Route path={routes.profile.path} exact component={Profile}/>
            <Route path={routes.mybadges.path} exact component={MyBadges}/>
            <Route path={routes.eyecaretips.path} exact component={EyeCareTips}/>
            <Route path={routes.resultscreen.path} exact component={ResultScreen}/> 
            <Route path={routes.story.path} exact component={Story}/>
            <Route path={routes.selectmode.path} exact component={SelectMode}/>
            <Route path={routes.explorermode.path} exact component={ExplorerMode}/>
            <Route path={routes.distancetestinstructions.path} exact component={DistanceTestInstructions}/>
            <Route path={routes.distancetest.path} exact component={DistanceTest}/>
            {/* <Route path={routes.astigmatismtestinstructions.path} exact component={AstigmatismTestInstructions}/>
            <Route path={routes.astigmatismtest.path} exact component={AstigmatismTest}/> */}
            <Route path={routes.nearvisiontestinstructions.path} exact component={NearVisionTestInstructions}/>
            <Route path={routes.nearvisiontest.path} exact component={NearVisionTest}/>
            <Route path={routes.colorvisiontestinstructions.path} exact component={ColorVisionTestInstructions}/>
            <Route path={routes.colorvisiontest.path} exact component={ColorVisionTest}/>
            <Route path={routes.lightsensitivitytestinstructions.path} exact component={LightSensitivityTestInstructions}/>
            <Route path={routes.lightsensitivitytest.path} exact component={LightSensitivityTest}/>
            <Route path={routes.myresults.path} exact component={MyResults}/>
          </Switch>

        </Router>
      </Provider>
       
    </div>
  );
}

export default App;
