const indexedDBConnect = (upgradeCallBack?: any) => {
    const database = 'ozzy';
    // Note: increment version every db structure update
    const version = 1;

    let DBOpenRequest = indexedDB.open(database, version);

    const promise = new Promise((res, rej) => {
        DBOpenRequest.addEventListener('success', (ev: any) => {
            res(ev.target.result);
        });
        DBOpenRequest.addEventListener('upgradeneeded', (ev: any) => {
            if(upgradeCallBack)upgradeCallBack(ev.target.result)
        });
        DBOpenRequest.addEventListener('error', (err: any) => {
            rej(err)
        });
    })
    return promise;
}


const indexDBTransaction = (upgradeCallBack?: any) => {
    let db: any = null;

    const connect = async () => {
        db = await indexedDBConnect(upgradeCallBack);
    }

    const insert = (table: string, data: any) => {
        const tx = db.transaction(table,'readwrite');
        // tx.oncomplete = (ev: any) => {  }    
        // tx.onerror = (ev: any) => { }
        const store = tx.objectStore(table);
        const request = store.add(data);
        request.onsuccess = () => { }
        request.onerror = () => { }
    }

    const get = (table: string, key: string, callback: any) => {
        const tx = db.transaction(table,'readonly');
        // tx.oncomplete = (ev: any) => {  }    
        // tx.onerror = (ev: any) => { }
        const store = tx.objectStore(table);
        const getRequest = store.get(key);
        getRequest.onsuccess = (ev: any) => {
            if(callback)callback(ev.target.result);
        }
        getRequest.onerror = (err: any) => { 
            console.log(err);
        }
    }

    const update = (table: string, data: any) => {
        const tx = db.transaction(table,'readwrite');
        // tx.oncomplete = (ev: any) => {  }    
        // tx.onerror = (ev: any) => { }
        const store = tx.objectStore(table);
        const request = store.put(data);
        request.onsuccess = () => { }
        request.onerror = () => { }
    }
    
    return {
        connect,
        insert,
        get,
        update
    }
}
export { indexedDBConnect }
export default indexDBTransaction;