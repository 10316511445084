import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import userSlice from '../../store/slices/user-slice'; 
import useGoto from '../../utils/hooks/useGoto';
import routes from '../../constants/routes.constants';
import neutralEmoji from '../../assets/svg/result-emoji/emoji-neutral.svg';
import Button from '../../components/ui/Button';

interface Props {
    className?: string,
    testType?: string
}

const NeutralResult : React.FC<Props>  = ({
    className = '',
    testType = ''
}) =>  {

    // VARIABLES
    const goto = useGoto();
    const dispatch = useDispatch();
    const [contentTitle, setContentTitle] = useState<string>('');
    const [contentDescription, setContentDescription] = useState<string>('');

    //HOOKS
    useEffect(() => {
        setPageText();
    }, []);


    //METHODS
    const goToDashboard = () => {
        dispatch(userSlice.actions.setDashboardSlideId(2));
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetest, 
                routes.dashboard,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToStory = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetest, 
                routes.story,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToExplore = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetest, 
                routes.explorermode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const setPageText = () => {
        switch (testType) {
            case 'distance':
                setContentTitle('You seem to have difficulties recognising small characters with one of your eyes');
                setContentDescription('To confirm this result, we recommend that you visit an eye care professional for a comprehensive eye health exam.');
                break;
            case 'astigmatism':
                setContentTitle('You seem to see some lines darker than others with one of your eyes');
                setContentDescription('You could be astigmatic. To confirm this result, we recommend that you visit an eye care professional for a comprehensive eye health exam.');
                break;
            case 'lightsensitivity':
                setContentTitle('You seem to have difficulties in distinguishing contrasts with one of your eyes');
                setContentDescription('To confirm this result, we recommend that you visit an eye care professional for a comprehensive eye health exam.');
                break;
        }
          
    }

    return (
    <>
        <div>
            <h1>You did good!</h1>
        </div>

        <div className='result__image--neutral'>
            <img src={neutralEmoji} alt="neutral-emoji" />
        </div>

        <div className='result__container'>
            <div className={`icon ${className}`}>

            </div>

            <div className='result__content-title'>
                <h2>{contentTitle}</h2>
            </div>

            <div className='result__content-description'>
                <caption>{contentDescription}</caption>
            </div>

            <div className='result__button'>
                <Button 
                    text="Go Back to Story Mode"
                    type="secondary"
                    outlined={false}
                    click={() => goToStory()}
                /> 
            </div>

            <div className='result__button'>
                <Button 
                    text="Play Another Game"
                    type="secondary"
                    outlined={false}
                    click={() => goToExplore()}
                /> 
            </div>

            <div className='result__button'>
                <Button 
                    text="See an Eye Care Practitioner"
                    type="secondary"
                    outlined={false}
                    click={() => goToDashboard()}
                /> 
            </div>

        </div>
    </>
    )
}

export default NeutralResult;