import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/slices/user-slice';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import evfLogo from '../../assets/images/evf-logo.png';
import ozzyBear from '../../assets/svg/ozzy-bear/ozzy-bear-waving.svg';


interface Props {

}


const SplashScreen : React.FC<Props>  = () =>  {
    const [B, E] = useBEM(routes.splashscreen.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const [startAnimation, setStartAnimation] = useState(false);
    const { id } = useSelector(getUser);

     // HOOKS
     useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (id) return gotoDashboard();
        gotoName()
    }, []);

    //METHODS
    const gotoDashboard = () => {
        setTimeout(() => {
            setStartAnimation(true);
            setTimeout(() => {
                goto.routeWithTransition(
                    routes.splashscreen, 
                    routes.dashboard,
                    'SLIDE_DOWN',
                    500
                );
            }, 1000)
        }, 1000)
    }

    const gotoName = () => {
        setTimeout(() => {
            setStartAnimation(true);
            setTimeout(() => {
                goto.routeWithTransition(
                    routes.splashscreen, 
                    routes.userinfo,
                    'SLIDE_DOWN',
                    500
                );
            }, 1000)
        }, 1000)
    }

    return(
        <div className={B() + ' page bg-blue'}>
            <div className={E('container')}>
                <img src={evfLogo} className={E('evf-logo')}  alt="evf-logo" />
                <img src={ozzyBear} className={E('ozzy-bear', [startAnimation ? 'animate' : ''])}  alt="ozzy-bear" />
            </div>
        </div>
    )

}

export default SplashScreen;