import React, { useEffect, useState } from 'react';

interface Props {
    name: string,
    type?: 'svg' | 'image' | 'png',
    extension?: 'png' | 'jpg',
    className?: string,
    click?: () => void,
    background?: boolean,
    customStyle?: any
}

const Icon : React.FC<Props>  = ({
        name,
        type = 'svg',
        extension = 'png',
        className,
        click,
        background = false,
        customStyle
    }) =>  {
    // VARIABLES
    const [ image, setImage ] = useState('');
    const folders = {
        svg: 'svg',
        image: 'images',
        png: 'png'
    }

    // METHODS
    const getFileExtension = () => {
        return type === 'svg' ? 'svg' : extension;
    }

    const loadImage = (imgName: string) => {
        const ext = getFileExtension();

        import(`../../assets/svg/icons/${imgName}.${ext}`).then(img => {
          setImage(img.default);
        });
    };

    const onClickHandler = () => {
        if(click)click();
    }

    const renderImage = () => {
        if(background) {
            return <div style={{backgroundImage: `url(${image})`}} className={className} onClick={onClickHandler}></div>;
        }
        return <img src={image} alt="" className={className} onClick={onClickHandler} style={customStyle}/>
    }

    // HOOKS
    useEffect(() => {
        loadImage(name);
    }, [name])

    return (
        <>
            { image && renderImage()}
        </>
    );
}

export default Icon;