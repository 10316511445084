
const routes = {
    splashscreen: {
        id: 'splash-screen',
        path: '/',
        label: 'Splash Screen'
    },
    userinfo: {
        id: 'user-info',
        path: '/user-info',
        label: 'User Information'
    },
    userCode: {
        id: 'user-info',
        path: '/user-info/:id',
        label: 'User Information'
    },
    dashboard: {
        id: 'dashboard',
        path: '/dashboard',
        label: 'Dashboard'
    },
    profile: {
        id: 'profile',
        path: '/profile',
        label: 'Profile'
    },
    mybadges: {
        id: 'my-badges',
        path: '/my-badges',
        label: 'My Badges'
    },
    eyecaretips: {
        id: 'eyecare-tips',
        path: '/eyecare-tips',
        label: 'Eyecare Tips'
    },
    resultscreen: {
        id: 'result-screen',
        path: '/result-screen',
        label: 'Result Screen'
    },
    story: {
        id: 'story',
        path: '/story',
        label: 'Story'
    },
    selectmode: {
        id: 'select-mode',
        path: '/select-mode',
        label: 'Select Mode'
    },
    explorermode: {
        id: 'explorer-mode',
        path: '/explorer-mode',
        label: 'Explorer Mode'
    },
    distancetestinstructions: {
        id: 'distance-test-instructions',
        path: '/distance-test-instructions',
        label: 'Distance Test Instructions'
    },
    distancetest: {
        id: 'distance-test',
        path: '/distance-test',
        label: 'Distance Test'
    },
    // astigmatismtestinstructions: {
    //     id: 'astigmatism-test-instructions',
    //     path: '/astigmatism-test-instructions',
    //     label: 'Astigmatism Test Instructions'
    // },
    // astigmatismtest: {
    //     id: 'astigmatism-test',
    //     path: '/astigmatism-test',
    //     label: 'Astigmatism Test'
    // },
    nearvisiontestinstructions: {
        id: 'nearvision-test-instructions',
        path: '/nearvision-test-instructions',
        label: 'Near Vision Test Instructions'
    },
    nearvisiontest: {
        id: 'nearvision-test',
        path: '/nearvision-test',
        label: 'Near Vision Test'
    },
    colorvisiontestinstructions: {
        id: 'colorvision-test-instructions',
        path: '/colorvision-test-instructions',
        label: 'Color Vision Test Instructions'
    },
    colorvisiontest: {
        id: 'colorvision-test',
        path: '/colorvision-test',
        label: 'Color Vision Test'
    },
    lightsensitivitytestinstructions: {
        id: 'lightsensitivity-test-instructions',
        path: '/lightsensitivity-test-instructions',
        label: 'Light Sensitivity Test Instructions'
    },
    lightsensitivitytest: {
        id: 'lightsensitivity-test',
        path: '/lightsensitivity-test',
        label: 'Light Sensitivity Test'
    },
    myresults: {
        id: 'my-results',
        path: '/my-results',
        label: 'My Results'
    }
}

export default routes;