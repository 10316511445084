import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import MobileDistanceInstruction40cm from '../../components/instructions/MobileDistanceInstruction40cm';
import WearYourEyeGlassesInstruction40cm from '../../components/instructions/WearYourEyeGlassesInstruction40cm';
import Button from '../../components/ui/Button';
import backIcon from '../../assets/svg/icons/delete-icon.svg';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/slices/user-slice';

interface Props {

}

const NearVisionTestInstructions : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B] = useBEM(routes.nearvisiontestinstructions.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);

    const swipeableStyles = {
        root: {
          padding: '0',
          height: '100%',
          width: '100%'
        },
        slideContainer: {
          padding: '0'
        }
    };

    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.nearvisiontestinstructions,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    const goToSelectMode = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.nearvisiontestinstructions, 
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToNearVisionTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.nearvisiontestinstructions, 
                routes.nearvisiontest,
                'SLIDE_LEFT',
                500
            );
        }, 250);
    } 

    return(
        <div className={B() + ' page bg-blue'}>
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToSelectMode()}/>                        
                        <h1>Near Vision</h1>
                    </div>

                    <SwipeableViews  enableMouseEvents={true} style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={0}> 

                        {/* swipeableindex 0  */}
                        <div className="instructions">
                            <MobileDistanceInstruction40cm />

                            <div className="instructions__swipe">Swipe to proceed</div>
                        </div>

                         {/* swipeableindex 1  */}
                         <div className="instructions">
                            <WearYourEyeGlassesInstruction40cm />

                            <div className="instructions__button">
                                <Button 
                                text="Start Game"
                                type="secondary"
                                outlined={false}
                                click={() => goToNearVisionTest()}
                                /> 
                            </div>
                         </div>
                         
                    </SwipeableViews>
                </div>
        </div>
    )
}

export default withAnimationTransition(NearVisionTestInstructions, {
    entrance: 'FADE_IN'
});
