import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import userSlice, { getUser } from '../../store/slices/user-slice';
import badgesSlice, { getBadges } from '../../store/slices/badges-slice';
import Button from '../../components/ui/Button';
import Badge from '../../components/badge/Badge';
import Modal from '../../components/Modal';
import ResultHappy from '../../components/result-emoji/Happy';
import ResultNeutral from '../../components/result-emoji/Neutral';
import ResultSad from '../../components/result-emoji/Sad';
import ProgressBar from '../../components/test-progressbar/ProgressBar';
import backIcon from '../../assets/svg/icons/delete-icon.svg';
import letterE from '../../assets/svg/distance-test/E6.svg';
import letterE5 from '../../assets/svg/distance-test/E5.svg';
import letterE4 from '../../assets/svg/distance-test/E4.svg';
import letterE3 from '../../assets/svg/distance-test/E3.svg';
import letterE2 from '../../assets/svg/distance-test/E2.svg';
import letterE1 from '../../assets/svg/distance-test/E1.svg';
import arrow from '../../assets/svg/distance-test/arrow.png';
import coverLeftEye from '../../assets/svg/pop-up/cover-left-eye.svg';

//const QUESTION_SLIDER_ID = 0;
const ANSWER_SLIDER_ID = 1;
const POPUP_SLIDER_ID = 2;
//const QUESTION_RIGHTEYE_SLIDER_ID = 3;
const ANSWER_RIGHTEYE_SLIDER_ID = 4;
const RESULT_SLIDER_ID = 5;

interface Props {

}

const DistanceTest: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM(routes.distancetest.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);
    const dispatch = useDispatch();
    const { badgesEarned, progress, notifications } = useSelector(getUser);
    const { distanceTestCompleted, astigmatismTestCompleted, nearVisionTestCompleted,
        colorVisionTestCompleted, lightSensitivityTestCompleted } = useSelector(getUser);
    const { distanceTestPassed, astigmatismTestPassed, nearVisionTestPassed,
        colorVisionTestPassed, lightSensitivityTestPassed } = useSelector(getUser);
    const { eyeTestBadge, eyeAmbassadorBadge, eyeTestChampBadge } = useSelector(getBadges);
    const [slideViewID, setSlideViewID] = useState<number>(ANSWER_SLIDER_ID);
    const [consecutiveWrongAnswerCounter, setConsecutiveWrongAnswerCounter] = useState<number>(0);
    const [totalCorrectAnswers, setTotalCorrectAnswers] = useState<number>(0);
    const [consecutiveWrongAnswerCounterRightEye, setConsecutiveWrongAnswerCounterRightEye] = useState<number>(0);
    const [totalCorrectAnswersRightEye, setTotalCorrectAnswersRightEye] = useState<number>(0);
    const [letterSize, setLetterSize] = useState<number>(6); //6-LARGEST 1-SMALLEST
    const [letterDirection, setLetterDirection] = useState<number>(0); //0-RIGHT 1-LEFT 2-UP 3-DOWN 
    const [letterClassName, setLetterClassName] = useState<string>('');
    const [leftEyePassedLevel, setLeftEyePassedLevel] = useState<boolean>(true);
    const [rightEyePassedLevel, setRightEyePassedLevel] = useState<boolean>(true);
    const [showCoverLeftEyeModal, setShowCoverLeftEyeModal] = useState<boolean>(false);
    const [showEyeTestBadgeModal, setShowEyeTestBadgeModal] = useState<boolean>(false);
    const [showExitConfirmationModal, setShowExitConfirmationModal] = useState<boolean>(false);
    const [imageAnimation, setImageAnimation] = useState<boolean>(false);
    const [pageAnimation, setPageAnimation] = useState<string>("");
    const [pageTitleAnimation, setPageTitleAnimation] = useState<string>("");
    const [allTestCompleted, setAllTestCompleted] = useState<boolean>(false);
    const [allTestPassed, setAllTestPassed] = useState<boolean>(false);
    const [showEyeAmbassadorBadgeModal, setShowEyeAmbassadorBadgeModal] = useState<boolean>(false);
    const [showEyeTestChampBadgeModal, setShowEyeTestChampBadgeModal] = useState<boolean>(false);
    const [checkTestCompletion, setCheckTestCompletion] = useState<boolean>(false);

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    let notificationCount = 0;

    // HOOKS
    useEffect(() => {
        connect();
        changeLetterDirection();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.distancetest,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    useEffect(() => {
        renderImage();
    }, [letterDirection]);

    useEffect(() => {
        if (imageAnimation) {
            setPageAnimation("expand-page");
            setPageTitleAnimation("expand-title");
        } else {
            setPageAnimation("");
            setPageTitleAnimation("");
        }
    }, [imageAnimation]);

    useEffect(() => {
        if (slideViewID === POPUP_SLIDER_ID) return setShowCoverLeftEyeModal(true);
        if (slideViewID === RESULT_SLIDER_ID && !eyeTestBadge) return setShowEyeTestBadgeModal(true);
        if (slideViewID === RESULT_SLIDER_ID) return dispatchResult();
    }, [slideViewID]);

    useEffect(() => {
        if (checkTestCompletion) {
            setAllTestCompleted(checkIfAllTestCompleted());
            setAllTestPassed(checkIfAllTestPassed());
        }
    }, [checkTestCompletion]);

    useEffect(() => {
        if (allTestCompleted) {
            if (slideViewID === RESULT_SLIDER_ID && !eyeAmbassadorBadge) setShowEyeAmbassadorBadgeModal(true);
        }
    }, [allTestCompleted]);

    useEffect(() => {
        if (allTestPassed) {
            if (slideViewID === RESULT_SLIDER_ID && !eyeTestChampBadge) setShowEyeTestChampBadgeModal(true);
        }
    }, [allTestPassed]);

    useEffect(() => {
        if (totalCorrectAnswers < 6 && consecutiveWrongAnswerCounter < 3) return setSlideViewID(ANSWER_SLIDER_ID);
        setSlideViewID(POPUP_SLIDER_ID);
    }, [totalCorrectAnswers]);

    useEffect(() => {
        if (consecutiveWrongAnswerCounter < 3) return setSlideViewID(ANSWER_SLIDER_ID);
        setSlideViewID(POPUP_SLIDER_ID);
        setShowCoverLeftEyeModal(true);
        setLeftEyePassedLevel(false);
    }, [consecutiveWrongAnswerCounter]);

    useEffect(() => {
        if (totalCorrectAnswersRightEye < 6 && consecutiveWrongAnswerCounterRightEye < 3) return setSlideViewID(ANSWER_RIGHTEYE_SLIDER_ID);
        setSlideViewID(RESULT_SLIDER_ID);
    }, [totalCorrectAnswersRightEye]);

    useEffect(() => {
        if (consecutiveWrongAnswerCounterRightEye < 3) return setSlideViewID(ANSWER_RIGHTEYE_SLIDER_ID);
        setSlideViewID(RESULT_SLIDER_ID);
        setRightEyePassedLevel(false);
    }, [consecutiveWrongAnswerCounterRightEye]);

    useEffect(() => {
        setSlideViewID(ANSWER_SLIDER_ID);
    }, []);

    useEffect(() => {
        if (imageAnimation) toggleExpandAnimation();
        // setTimeout(() => {
        //     autoSlide();
        // }, 500);
    }, [slideViewID]);

    //METHODS
    const goToSelectMode = () => {
        updateNotificationInterruptedTest();
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetest,
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const getNotificationCount = () => {
        let count = 0;
        notifications?.forEach(() => {
            count = count + 1;
        });
        return count;
    }

    const updateNotificationsFirstEyeTestComplete = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You've completed your first eye test! Keep going!", activity: "Completed first eye test (either on Story mode or Explorer mode)", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationsAllTestComplete = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You have just completed all the eye tests! Take note of your results on the Results Page and head on to the Charitable Clinic Search page to call your desired clinic.", activity: "Completed all eye tests", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationsAllTestPassed = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You have just passed all the eye tests! Take note of your results on the Results Page and head on to the Charitable Clinic Search page to call your desired clinic.", activity: "Completed all eye tests", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationInterruptedTest = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            [{ id: notificationCount + 1, description: "Oh sad, You haven't completed your eye tests yet. :(. Would you like to complete it now? Head to Explorer Mode to complete your previously started test!", activity: "Interrupted eye test completion", new: true }];
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const checkIfAllTestCompleted = () => {
        let retVal = true;
        if (!distanceTestCompleted || !astigmatismTestCompleted || !nearVisionTestCompleted ||
            !colorVisionTestCompleted || !lightSensitivityTestCompleted) retVal = false;
        return retVal;
    }

    const checkIfAllTestPassed = () => {
        let retVal = true;
        if (!distanceTestPassed || !astigmatismTestPassed || !nearVisionTestPassed ||
            !colorVisionTestPassed || !lightSensitivityTestPassed) retVal = false;
        return retVal;
    }

    // const autoSlide = () => {
    //     switch (slideViewID) {
    //         case QUESTION_SLIDER_ID:
    //             return setSlideViewID(ANSWER_SLIDER_ID);
    //         case QUESTION_RIGHTEYE_SLIDER_ID:
    //             return setSlideViewID(ANSWER_RIGHTEYE_SLIDER_ID);
    //     }
    // }

    const toggleExpandAnimation = () => {
        setImageAnimation(prevState => !prevState);
    }

    const proceedOnClick = () => {
        setShowCoverLeftEyeModal(false);
        resetLetterSize();
        setSlideViewID(ANSWER_RIGHTEYE_SLIDER_ID);
    }

    const claimEyeTestBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress < 50) progress_ = 50;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeTestBadge(true));
        }, 250);
        updateNotificationsFirstEyeTestComplete();
        setShowEyeTestBadgeModal(false);
    }

    const claimEyeAmbassadorBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress <= 75) progress_ = 75;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeAmbassadorBadge(true));
        }, 250);
        updateNotificationsAllTestComplete();
        setShowEyeAmbassadorBadgeModal(false);
    }

    const claimEyeTestChampBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress <= 75) progress_ = 75;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeTestChampBadge(true));
        }, 250);
        updateNotificationsAllTestPassed();
        setShowEyeTestChampBadgeModal(false);
    }

    const resetLetterSize = () => {
        setLetterSize(6);
    }

    const handleOnAnswerClick = (direction: number) => {
        setTimeout(() => {
            if (checkAnswer(direction)) {
                handleCorrectAnswer();
            } else {
                handleWrongAnswer();
            }
        }, 250);
    }

    const checkAnswer = (direction: number) => {
        if (direction === letterDirection) return true;
        return false;
    }

    const handleCorrectAnswer = () => {
        setConsecutiveWrongAnswerCounter(0);
        handleDecrementLetterSize();
        changeLetterDirection();
        handleIncrementTotalCorrectAnswers();
    }

    const handleWrongAnswer = () => {
        changeLetterDirection();
        setConsecutiveWrongAnswerCounter(prevValue => prevValue + 1);
    }

    const handleDecrementLetterSize = () => {
        setLetterSize(prevLetterSize => prevLetterSize - 1);
    }

    const handleIncrementTotalCorrectAnswers = () => {
        setTotalCorrectAnswers(prevTotalCorrectAnswers => prevTotalCorrectAnswers + 1);
    }

    const handleOnAnswerRightEyeClick = (direction: number) => {
        setTimeout(() => {
            if (checkAnswer(direction)) {
                handleCorrectAnswerRightEye();
            } else {
                handleWrongAnswerRightEye();
            }
        }, 250);
    }

    const handleCorrectAnswerRightEye = () => {
        setConsecutiveWrongAnswerCounterRightEye(0);
        handleDecrementLetterSize();
        changeLetterDirection();
        handleIncrementTotalCorrectAnswersRightEye();
    }

    const handleWrongAnswerRightEye = () => {
        changeLetterDirection();
        setConsecutiveWrongAnswerCounterRightEye(prevValue => prevValue + 1);
    }

    const handleIncrementTotalCorrectAnswersRightEye = () => {
        setTotalCorrectAnswersRightEye(prevTotalCorrectAnswers => prevTotalCorrectAnswers + 1);
    }

    const changeLetterDirection = () => {
        let direction = randomizeLetterDirection();
        let letterClassName = getDirectionClassName(direction);
        setLetterClassName(letterClassName);
        setLetterDirection(direction);
    }

    const randomizeLetterDirection = () => {
        let newDirection = Math.floor(Math.random() * 4);

        while (letterDirection === newDirection) {
            if (letterDirection !== newDirection) {
                break;
            }
            newDirection = Math.floor(Math.random() * 4);
        }

        return newDirection;
    }

    const getDirectionClassName = (direction: any) => {
        let className = "";
        switch (direction) {
            case 0:
                className = "direction__right";
                break;
            case 1:
                className = "direction__left";
                break
            case 2:
                className = "direction__up";
                break;
            case 3:
                className = "direction__down";
                break;
        }
        return className;
    }

    const renderProgressBar = () => {
        switch (totalCorrectAnswers) {
            case 0:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-0" type="png" />
                </div>
            case 1:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-20" type="png" />
                </div>
            case 2:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-50" type="png" />
                </div>
            case 3:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-70" type="png" />
                </div>
            case 4:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-80" type="png" />
                </div>
            case 5:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-90" type="png" />
                </div>
        }
    }

    const renderProgressBarRightEye = () => {

        switch (totalCorrectAnswersRightEye) {
            case 0:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-0" type="png" />
                </div>
            case 1:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-20" type="png" />
                </div>
            case 2:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-50" type="png" />
                </div>
            case 3:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-70" type="png" />
                </div>
            case 4:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-80" type="png" />
                </div>
            case 5:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-90" type="png" />
                </div>
        }

    }

    const renderImage = () => {
        switch (letterSize) {
            case 6:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE} className={letterClassName} />
                </div>
            case 5:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE5} className={letterClassName} />
                </div>
            case 4:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE4} className={letterClassName} />
                </div>
            case 3:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE3} className={letterClassName} />
                </div>
            case 2:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE2} className={letterClassName} />
                </div>
            case 1:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE1} className={letterClassName} />
                </div>
        }
    }


    const renderImageRightEye = () => {
        switch (letterSize) {
            case 6:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE} className={letterClassName} />
                </div>
            case 5:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE5} className={letterClassName} />
                </div>
            case 4:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE4} className={letterClassName} />
                </div>
            case 3:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE3} className={letterClassName} />
                </div>
            case 2:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE2} className={letterClassName} />
                </div>
            case 1:
                return <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                    <img src={letterE1} className={letterClassName} />
                </div>
        }
    }

    const renderResult = () => {
        if (localStorage.getItem("persistantState") !== null) {
            const localData = JSON.parse(localStorage.getItem("persistantState") || '')
            const userID = localData.user.userInfoId;

            if (leftEyePassedLevel && rightEyePassedLevel) {
                const result = {
                    distanceGameResult: "Happy",
                    userInfoId: userID
                }
                fetch('https://ozzy-prd-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(result),
                })
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                return <ResultHappy className="bg-orange" testType='distance' />

            } else if (leftEyePassedLevel || rightEyePassedLevel) {
                const result = {
                    distanceGameResult: "Neutral",
                    userInfoId: userID
                }
                fetch('https://ozzy-prd-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(result),
                })
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                return <ResultNeutral className="bg-orange" testType='distance' />

            } else {
                const result = {
                    distanceGameResult: "Sad",
                    userInfoId: userID
                }
                fetch('https://ozzy-prd-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(result),
                })
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                return <ResultSad className="bg-orange" testType='distance' />

            }
        } else {
            return <></>
        }
    }

    const dispatchResult = () => {
        let distanceTestPassed_ = false;
        if (leftEyePassedLevel && rightEyePassedLevel) distanceTestPassed_ = true;

        setTimeout(() => {
            dispatch(userSlice.actions.setDistanceTestCompleted(true));
            dispatch(userSlice.actions.setDistanceTestPassed(distanceTestPassed_));
            setCheckTestCompletion(true);
        }, 250);
    }

    const renderPageTitle = () => {
        return <div className={`flex-row title-wrapper ${pageTitleAnimation || ''}`}>
            <img src={backIcon} alt="back-icon" onClick={() => setShowExitConfirmationModal(true)} />
            <h1>Distance Vision Test</h1>
        </div>
    }

    return (
        <div className={B() + ` page bg-orange ${pageAnimation || ''}`}>
            <SwipeableViews enableMouseEvents={false} disabled style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={slideViewID}>
                {/* SLIDER_ID 0 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {/* {renderProgressBar()} */}

                    {/* {renderImage()} */}

                </div>

                {/* SLIDER_ID 1 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {/* {renderProgressBar()} */}

                    <div>
                        <h1 className='insText'>Tap the direction of the E</h1>
                    </div>

                    {renderImage()}

                    <div className={E("answer__grid")}>
                        <div className={E("grid-item", "up")} onClick={() => handleOnAnswerClick(2)}>
                            <img src={arrow} className="direction__up" />
                        </div>

                        <div className={E("grid-item", "right")} onClick={() => handleOnAnswerClick(0)}>
                            <img src={arrow} />
                        </div>

                        <div className={E("grid-item", "down")} onClick={() => handleOnAnswerClick(3)}>
                            <img src={arrow} className="direction__down" />
                        </div>

                        <div className={E("grid-item", "left")} onClick={() => handleOnAnswerClick(1)}>
                            <img src={arrow} className="direction__left" />
                        </div>
                    </div>
                </div>

                {/* SLIDER_ID 2 */}
                <div className="flex-col">

                </div>

                {/* SLIDER_ID 3 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {/* {renderProgressBarRightEye()} */}

                    {/* {renderImageRightEye()} */}
                </div>

                {/* SLIDER_ID 4 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {/* {renderProgressBarRightEye()} */}

                    <div>
                        <h1 className='insText'>Tap the direction of the E</h1>
                    </div>

                    {renderImageRightEye()}

                    <div className={E("answer__grid")}>
                        <div className={E("grid-item", "up")} onClick={() => handleOnAnswerRightEyeClick(2)}>
                            <img src={arrow} className="direction__up" />
                        </div>

                        <div className={E("grid-item", "right")} onClick={() => handleOnAnswerRightEyeClick(0)}>
                            <img src={arrow} />
                        </div>

                        <div className={E("grid-item", "down")} onClick={() => handleOnAnswerRightEyeClick(3)}>
                            <img src={arrow} className="direction__down" />
                        </div>

                        <div className={E("grid-item", "left")} onClick={() => handleOnAnswerRightEyeClick(1)}>
                            <img src={arrow} className="direction__left" />
                        </div>
                    </div>
                </div>

                {/* SLIDER_ID 5 */}
                <div className="result-emoji">
                    {renderResult()}
                </div>
            </SwipeableViews>

            <Modal show={showExitConfirmationModal} popup closeModalOnOutsideClick={false} hideCloseButton>
                <div className="confirmation">
                    <div className="confirmation__image-container">
                        <img src={backIcon} alt="back-icon" onClick={() => setShowExitConfirmationModal(false)} />
                    </div>

                    <div>
                        <h1>Are you sure?</h1>
                    </div>

                    <div>
                        <caption>Your progress will not be saved if you exit now.</caption>
                    </div>

                    <div className="confirmation__button-container">
                        <Button
                            text="Yes"
                            type="primary"
                            outlined={false}
                            click={() => goToSelectMode()}
                        />
                    </div>

                </div>
            </Modal>

            <Modal show={showCoverLeftEyeModal} popup closeModalOnOutsideClick={false} hideCloseButton>
                <div className="popup">
                    <div className="popup__image">
                        <img src={coverLeftEye} alt="cover-left-eye" />
                    </div>

                    <div>
                        <h1>Well done!</h1>
                    </div>

                    <div>
                        <caption>Now, cover your right eye.</caption>
                    </div>

                    <div className="popup__button">
                        <Button
                            text="Proceed"
                            type="primary"
                            outlined={false}
                            click={() => proceedOnClick()}
                        />
                    </div>
                </div>
            </Modal>

            <Modal show={showEyeTestBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>Wow! That's a first!</h1> <br />
                        <caption>You have earned the <p>Eye Test Goalie</p> badge</caption>
                    </div>
                    <Badge name="eye-test-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTestBadgeOnClick()}
                    />
                </div>
            </Modal>

            <Modal show={showEyeAmbassadorBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>That's impressive!</h1> <br />
                        <caption>You have earned the <p>Eye Test Ambassador</p> badge</caption>
                    </div>
                    <Badge name="eye-ambassador-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeAmbassadorBadgeOnClick()}
                    />
                </div>
            </Modal>

            <Modal show={showEyeTestChampBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>You're a champ!</h1> <br />
                        <caption>You have earned the <p>Eye Test Champ</p> badge</caption>
                    </div>
                    <Badge name="eye-test-champ-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTestChampBadgeOnClick()}
                    />
                </div>
            </Modal>
        </div>
    )

}

export default withAnimationTransition(DistanceTest, {
    entrance: 'FADE_IN'
});
