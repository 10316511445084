import React from 'react';
import coverYourRightEye from '../../assets/svg/instructions/cover-your-right-eye.svg';

interface Props {

}

const CoverRightEyeInstruction: React.FC<Props> = () => {
    //Without pressing your eyelid, cover your right eye with your hand.

    return (
        <>
            <div className='instruction__image'>
                <img src={coverYourRightEye} alt="mobile-distance-instruction" />
            </div>

            <div className='breadcrumb__container'>
                <div className='breadcrumb'></div>
                <div className='breadcrumb'></div>
                <div className='breadcrumb--selected'></div>
            </div>

            <div className='instruction__title'>
                <h2>Cover Your Left Eye</h2>
            </div>

            {/* <div className='instruction__description'>
                <caption>During the game, you will be tasked to indicate which way the open side of the 'C' is facing.</caption>
            </div> */}

            {/* <div className='instruction__description'>
                <caption>During the game you will be tasked with identifying the direction in which the letter ‘E’ is facing.</caption>
            </div> */}
        </>
    )
}

export default CoverRightEyeInstruction;