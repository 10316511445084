import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import userSlice, { getUser } from '../../store/slices/user-slice'; 
import badgesSlice, { getBadges } from '../../store/slices/badges-slice';
import Button from '../../components/ui/Button';
import Badge from '../../components/badge/Badge';
import Modal from '../../components/Modal';

import backIcon from '../../assets/svg/icons/arrow-back-white.svg';
import storyCover from '../../assets/svg/story/story-page-0.svg';
import storyPage1 from '../../assets/svg/story/story-page-1.png';
import storyPage2 from '../../assets/svg/story/story-page-2.png';
import storyPage3 from '../../assets/svg/story/story-page-3.png';
import storyPage4 from '../../assets/svg/story/story-page-4.png';
import storyPage5 from '../../assets/svg/story/story-page-5.svg';
import storyPage6 from '../../assets/svg/story/story-page-6.svg';
import storyPage7 from '../../assets/svg/story/story-page-7.png';
import storyPage8 from '../../assets/svg/story/story-page-8.png';
import storyPage9 from '../../assets/svg/story/story-page-9.png';
import storyPage10 from '../../assets/svg/story/story-page-10.png';
import storyPage11 from '../../assets/svg/story/story-page-11.svg';
import storyPage12 from '../../assets/svg/story/story-page-12.png';
import storyPage13 from '../../assets/svg/story/story-page-13.png';
import storyPage14 from '../../assets/svg/story/story-page-14.png';
import storyPage15 from '../../assets/svg/story/story-page-15.svg';
import storyPage16 from '../../assets/svg/story/story-page-16.png';
import storyPage17 from '../../assets/svg/story/story-page-17.svg';
import storyPage18 from '../../assets/svg/story/story-page-18.png';
import storyPage19 from '../../assets/svg/story/story-page-19.svg';
import storyPage20 from '../../assets/svg/story/story-page-20.png';
import storyPage21 from '../../assets/svg/story/story-page-21.png';
import storyPage22 from '../../assets/svg/story/story-page-22.png';



interface Props {

}

const Story : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM(routes.story.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);
    const dispatch = useDispatch();
    const { progress, badgesEarned, notifications } = useSelector(getUser);
    const [progress_, setProgress] = useState<number>(0);
    const [badgesEarned_, setBadgesEarned] = useState<number>(0);
    const { storyBadge } = useSelector(getBadges);
    const [showStoryBadgeModal, setShowStoryBadgeModal] = useState(false);
    const [swipeableIndex, setSwipeableIndex] = useState<number>(0);
    const [currentStory, setCurrentStory] = useState<number>(0);

    const swipeableStyles = {
        root: {
          padding: '0',
          width: '100%',
          
        },
        slideContainer: {
          padding: '0'
        }
    };

    let notificationCount = 0;

    // HOOKS
    useEffect(() => {
        connect();
        const current = window.localStorage.getItem('currentStory');
        if (current !== null && current !== "") {
            setSwipeableIndex(JSON.parse(current));
        }
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    useEffect(() => {
        //if (swipeableIndex === 20 && !storyBadge) setShowStoryBadgeModal(true);
        if(swipeableIndex > 20) setSwipeableIndex(0);
        window.localStorage.setItem('currentStory', JSON.stringify(swipeableIndex));
    }, [swipeableIndex])

    //METHODS
    const gotoName = () => {
        goto.routeWithTransition(
            routes.story,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    const handleDecrementSwipeableIndex = () => {
        setSwipeableIndex(prevIndex => prevIndex - 1);
    }

    const handleIncrementSwipeableIndex = () => {
        //setSwipeableIndex(prevIndex => prevIndex + 1)
        if (swipeableIndex >= 20) {
            setTimeout(() => {
                localStorage.removeItem('currentStory');
                goto.routeWithTransition(
                    routes.story,
                    routes.dashboard,
                    'SLIDE_DOWN',
                    500
                );
            }, 1000);
        } else {
            setSwipeableIndex(prevIndex => prevIndex + 1)
        }

    }

    const goToDashboard = () => {
        if (swipeableIndex !== 22) {
            updateNotificationsInterruptedStory();
        }
        setTimeout(() => {
            goto.routeWithTransition(
                routes.story, 
                routes.selectmode,
                'SLIDE_RIGHT',
                500
            );
        }, 250);
    }

    const handleSkipToDistancePage = () => {
        setSwipeableIndex(11);
    }

    const handleSkipToEnd = () => {
        setSwipeableIndex(22);
    }

    const goToDistanceTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.story, 
                routes.distancetestinstructions,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const goToAstigmatistmTest = () => {
        // setTimeout(() => {
        //     goto.routeWithTransition(
        //         routes.story, 
        //         routes.astigmatismtestinstructions,
        //         'SLIDE_DOWN',
        //         500
        //     );
        // }, 250);
    }

    const goToNearVisionTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.story, 
                routes.nearvisiontestinstructions,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const goToColorVisionTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.story, 
                routes.colorvisiontestinstructions,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const goToLightSensitivityTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.story, 
                routes.lightsensitivitytestinstructions,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const getNotificationCount = () => {
        let count = 0;
        notifications?.forEach(() => {
            count = count + 1;
        });
        return count;
    }

    const updateNotificationsStory = () => {
        notificationCount = getNotificationCount();
        let notifs_:Array<{id: number, description: string, activity: string, new:boolean}> = 
            ([{id: notificationCount + 1, description: "Did you like the story of our Baby Bear? You can always view the story anytime!", activity: "Completed Story mode", new: true},
            {id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true}]);
        dispatch(userSlice.actions.saveNotifications({notification:notifs_}));
    }

    const updateNotificationsInterruptedStory = () => {
        notificationCount = getNotificationCount();
        let notifs_:Array<{id: number, description: string, activity: string, new:boolean}> = 
            [{id: notificationCount + 1, description: "Poor baby bear is waiting for you to finish her eye test journey :(. Head on to Story Mode and finish the journey and take the tests!", activity: "Interrupted navigation of story and eye test completion", new: true}];
        dispatch(userSlice.actions.saveNotifications({notification:notifs_}));
    }

    const claimStoryBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress < 75) {
            progress_ = 50;
        } else if (progress && progress <= 75) {
            progress_ = 75;
        }
        
        localStorage.removeItem('currentStory');

        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setStoryBadge(true));
        }, 250);
        updateNotificationsStory();
        setShowStoryBadgeModal(false);
        goto.routeWithTransition(
            routes.story,
            routes.dashboard,
            'SLIDE_DOWN',
            500
        );
    }

    return(
        <div className={B() + ' page bg-blue'}>
            <SwipeableViews enableMouseEvents={false} disabled style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={swipeableIndex}> 
                {/* swipeableIndex 0 */}
                <div className="flex-col-nh">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                           
                    </div>

                    <div className={E("story__cover")}  onClick={() => handleIncrementSwipeableIndex()}>
                        <h2>
                            OZZY
                        </h2>
                        <p>
                            The Optometrist
                        </p>
                    </div>

                    <div className={E("story")}  onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page--w100")}>
                            <img src={storyCover} alt="story-page"/>
                        </div>
                    </div>


                </div>

                {/* swipeableIndex 1 */}
                <div className="flex-col-hnone">
                    <div className="flex-row title-wrapper pt-1"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage1} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 2 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage2} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 3 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage3} alt="story-page"/>
                        </div>
                    </div>
                </div>
                
                {/* swipeableIndex 4 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage4} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 5 */}
                <div className="flex-col-nh">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page--w100")}>
                            <img src={storyPage5} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 6 */}
                <div className="flex-col-nh">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page--w100")}>
                            <img src={storyPage6} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 7 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img  src={storyPage7} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 8 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img  src={storyPage8} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 9 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img  src={storyPage9} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 10 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToDistancePage}>Skip &gt;</caption>                                           
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img  src={storyPage10} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 11 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                     
                    </div>

                    <div className={E("story__with-button")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img  src={storyPage11} alt="story-page"/>
                        </div>
                    </div>

                    <div className="button-wrapper">
                        <Button 
                            text="Let's Go!"
                            type="secondary"
                            //disabled={nextNameButtonDisabled}
                            outlined={false}
                            click={() => goToDistanceTest()}
                            //className={E("button--name", [startAnimation ? "animate" : "animate-out"])}
                            /> 
                    </div>
                </div>

                {/* <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                        
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage12} alt="story-page"/>
                        </div>
                    </div>
                </div> */}

                {/* <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                     
                    </div>

                    <div className={E("story__with-button")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage13} alt="story-page"/>
                        </div>
                        
                    </div>

                    <div className="button-wrapper">
                        <Button 
                            text="Let's Go!"
                            type="secondary"
                            //disabled={nextNameButtonDisabled}
                            outlined={false}
                            click={() => goToAstigmatistmTest()}
                            //className={E("button--name", [startAnimation ? "animate" : "animate-out"])}
                            /> 
                    </div>
                </div> */}

                {/* swipeableIndex 12 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                        
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage16} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 13 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                     
                    </div>

                    <div className={E("story__with-button")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage17} alt="story-page"/>
                        </div>
                    </div>

                    <div className="button-wrapper">
                        <Button 
                            text="Let's Go!"
                            type="secondary"
                            //disabled={nextNameButtonDisabled}
                            outlined={false}
                            click={() => goToNearVisionTest()}
                            //className={E("button--name", [startAnimation ? "animate" : "animate-out"])}
                            /> 
                    </div>
                </div>

                {/* swipeableIndex 14 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                        
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage14} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 15 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                     
                    </div>

                    <div className={E("story__with-button")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage15} alt="story-page"/>
                        </div>
                    </div>

                    <div className="button-wrapper">
                        <Button 
                            text="Let's Go!"
                            type="secondary"
                            //disabled={nextNameButtonDisabled}
                            outlined={false}
                            click={() => goToColorVisionTest()}
                            //className={E("button--name", [startAnimation ? "animate" : "animate-out"])}
                            /> 
                    </div>
                </div>

                {/* swipeableIndex 16 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                        
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage18} alt="story-page"/>
                        </div>
                        
                    </div>
                </div>

                {/* swipeableIndex 17 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                     
                    </div>

                    <div className={E("story__with-button")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage19} alt="story-page"/>
                        </div>
                    </div>

                    <div className="button-wrapper">
                        <Button 
                            text="Let's Go!"
                            type="secondary"
                            //disabled={nextNameButtonDisabled}
                            outlined={false}
                            click={() => goToLightSensitivityTest()}
                            //className={E("button--name", [startAnimation ? "animate" : "animate-out"])}
                            /> 
                    </div>
                </div>

                {/* swipeableIndex 18 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToEnd}>Skip &gt;</caption>                                         
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage20} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 19 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>
                        <caption onClick={handleSkipToEnd}>Skip &gt;</caption>                                         
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage21} alt="story-page"/>
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 20 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                                        
                    </div>

                    <div className={E("story")} onClick={() => handleIncrementSwipeableIndex()}>
                        <div className={E("story__page")}>
                            <img src={storyPage22} alt="story-page"/>
                        </div>
                    </div>
                </div>

                
            </SwipeableViews>

            <Modal show={showStoryBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>Way to go!</h1> <br />
                        <caption>You have earned the <p>Story Reader</p> badge</caption>
                    </div>
                    <Badge name="story-badge"/>

                    <Button 
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimStoryBadgeOnClick()}
                    /> 
                </div>
            </Modal>
        </div>


    )

}

export default withAnimationTransition(Story, {
    entrance: 'FADE_IN'
});
