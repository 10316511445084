import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userSlice, { getUser } from '../../../store/slices/user-slice';
import routes from '../../../constants/routes.constants';

import Avatar from '../../../components/avatar/Avatar';
import Modal from '../../../components/Modal';
import notificationIcon from '../../../assets/svg/icons/notification-icon.svg';
import Button from '../../../components/ui/Button';
import useGoto from '../../../utils/hooks/useGoto';


interface Props {
    avatar: any,
    name: any,
    notificationCount: any
}

const Header: React.FC<Props> = ({
    avatar,
    name,
    notificationCount
}) => {
    const dispatch = useDispatch();
    const goto = useGoto();
    const { notifications } = useSelector(getUser);
    const [showNotificationModal, setShowNotificationModal] = useState(false);

    let notifs = notifications?.map(i => ({ ...i }));
    let sortedNotifs = notifs?.reverse();

    const handleNotificationOnClick = () => {
        setShowNotificationModal(true);
    }

    const handleNewPlayer = () => {
        localStorage.clear();
        setTimeout(() => {
            goto.routeWithTransition(
                routes.dashboard,
                routes.userinfo,
                'SLIDE_RIGHT',
                500
            );
        }, 250);
    }

    const handleNotificationOnClose = () => {
        if (notifications) {
            dispatch(userSlice.actions.setReadNotification());
        }
        setShowNotificationModal(false);
    }

    return (
        <>
            <div className="flex-row">
                <Avatar avatar={avatar} />

                <div className="message-wrapper">
                    <h1>Hello {name}</h1> <br />
                    <caption>Let's get awesome today!</caption>
                </div>

                <div className="bell-wrapper">
                    <div className="bell">
                        <img src={notificationIcon} alt="notification-icon" onClick={() => handleNotificationOnClick()} />
                    </div>
                    {/* { notificationCount > 0 ? 
                    <div className="notification__count">
                        {notificationCount}
                    </div> 
                    : 
                    null
                } */}

                </div>

                <Modal show={showNotificationModal} notification hideCloseButton onClose={() => handleNotificationOnClose()}>
                    <div className="modal__notifications">
                        <div className="modal__notifications__header">
                            <h1>Notifications</h1>
                        </div>

                        {sortedNotifs?.map((notif, index) => {
                            return <div className={`modal__notifications__description new-${notif.new}`} key={index}>{notif.description}</div>
                        })}

                    </div>

                </Modal>
            </div>

            <Button
                text="New Player"
                type="primary"
                outlined={false}
                className="new-player-button"
                click={() => handleNewPlayer()}
            />
        </>
    )

}

export default Header;