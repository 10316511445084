import React from 'react';
import Icon from '../../../assets/images/icon.png'
import { isApple } from '../../../utils/util'

interface Props {
 data:any
}

const ResultPage : React.FC<Props> = ({
    data
}) => {
    console.log(data);
    
    const handleMapClick = (name:any, address:any) => {
        const query = encodeURIComponent(name+" "+address ?? '');
        if (isApple()) {
            window.open(`https://maps.apple.com/?q=${query}`)
        } else {
            window.open(`https://maps.google.com/?q=${query}`);
        }
    }

    const renderStores = () => {
        const jsx = data.map((d:any,k:any) => {
            return (
                <div className={`${'res-btn'} ${k===0 && 'active'}`} key={k}>
                    <div className="store-logo">
                        <img src={Icon}/>
                    </div>
                    <div className="store-details">
                        <div className="store-name">{d.storeName}</div>
                        <div className="store-address">{d.storeAddress}</div>
                        {/* <div className="store-availability">
                            {
                                d.availability.map((a:any,v:any) => {
                                    return (
                                        <div className="day-start-end" key={v}>
                                            <span className="day">
                                                {concatDays(a.day)}
                                            </span>
                                            <span className="start-end">{`${a.start} - ${a.end}`}</span>
                                        </div>
                                    )
                                })
                            }
                        </div> */}
                        <button className="redirect-map" onClick={() => handleMapClick(d.storeName, d.storeAddress)}>
                            Click Map
                        </button>
                    </div>

                </div>
            )
        })
        
        return jsx
    }

    const concatDays = (days:any) => {
        let daysText = ''

        days.forEach((d:any)=>{
            daysText = `${daysText}${d}`
        })
        return daysText
    }

    return(
        data.length === 0 ? (
            <div className="no-result">No Result found</div>
        ) : (
            <div className='result-page'>
                <div className='results'>
                    {renderStores()}
                </div>
            </div>
        )
    )
}

export default ResultPage;