import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userSlice, { getUser } from '../../store/slices/user-slice';
import badgesSlice, { getBadges } from '../../store/slices/badges-slice';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import SwipeableViews from 'react-swipeable-views';
import Button from '../../components/ui/Button';
import Header from './components/Header';
import Navigation from '../../components/navigation/Navigation';
import Modal from '../../components/Modal';
import Badge from '../../components/badge/Badge';

import babyBear from '../../assets/svg/baby-bear/baby-bear-dashboard.svg';
import speechBubble from '../../assets/svg/speech-bubble.svg';
import babyBearReading from '../../assets/svg/baby-bear/baby-bear-reading.svg';
import ozzyBear from '../../assets/svg/ozzy-bear/ozzy-bear-dashboard.svg';


interface Props {

}

const Dashboard: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM(routes.dashboard.id);
    const goto = useGoto();
    const dispatch = useDispatch();
    const [connect] = useIndexedDB();
    const { id, name, avatar, previousPage, dashboardSlideId, notifications } = useSelector(getUser);
    const [swipeableIndex, setSwipeableIndex] = useState<number>(1);
    const { welcomeBadge } = useSelector(getBadges);
    const [avatar_, setAvatar] = useState(avatar);
    const [name_, setName] = useState(name);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [welcomeBadgeClaimed, setWelcomeBadgeClaimed] = useState(welcomeBadge);

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    let notificationCount = 0;

    // HOOKS
    useEffect(() => {
        connect();
        notificationCount = getNotificationCount();
        if (previousPage === routes.eyecaretips.id) setSwipeableIndex(0);
        if (dashboardSlideId === 2) setSwipeableIndex(2);
        if (!welcomeBadgeClaimed) return setShowWelcomeModal(true);
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.dashboard,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    // METHODS
    const getNotificationCount = () => {
        let count = 0;
        notifications?.forEach(() => {
            count = count + 1;
        });

        return count;
    }

    const getNewNotificationCount = () => {
        let count = 0;
        notifications?.forEach(notif => {
            if (notif?.new) {
                count = count + 1;
            }
        });

        return count;
    }

    const updateNotifications = () => {
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> = [{ id: notificationCount + 1, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }];
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const claimWelcomeBadgeOnClick = () => {
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(4));
            dispatch(badgesSlice.actions.setWelcomeBadge(true));
        }, 250);
        setShowWelcomeModal(false);
    }

    const goToEyeCareTips = () => {
        resetDashboardSlideID();
        setTimeout(() => {
            goto.routeWithTransition(
                routes.dashboard,
                routes.eyecaretips,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToStory = () => {
        resetPageFrom();
        resetDashboardSlideID();
        setTimeout(() => {
            goto.routeWithTransition(
                routes.dashboard,
                routes.selectmode,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const goToClinicSearch = () => {
        resetPageFrom();
        setDashboardSlideID();
        setTimeout(() => {
            goto.routeWithTransition(
                routes.dashboard,
                routes.resultscreen,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const resetPageFrom = () => {
        setTimeout(() => {
            dispatch(userSlice.actions.setPreviousPage(""));
        }, 250);
    }

    const setDashboardSlideID = () => {
        setTimeout(() => {
            dispatch(userSlice.actions.setDashboardSlideId(2));
        }, 250);
    }

    const resetDashboardSlideID = () => {
        setTimeout(() => {
            dispatch(userSlice.actions.setDashboardSlideId(1));
        }, 250);
    }

    return (
        <div className={B() + ' page bg-light'}>
            {/* <div className="version-text">
                version 1.2
            </div> */}
            <div className="flex-col">

                <Header avatar={avatar_} name={name_} notificationCount={getNewNotificationCount()} />

                <SwipeableViews enableMouseEvents style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={swipeableIndex}>
                    <div className={E('swipeable-1')}>
                        <div className={E('bear')}>
                            <img src={babyBearReading} className={E("bear__body")} alt="baby-bear-reading" />
                        </div>

                        <div>
                            <p>Know how to take care of your eyes</p>
                        </div>

                        <div>
                            <Button
                                text="Start Learning"
                                type="simple"
                                outlined={false}
                                icon="arrow-forward-green"
                                click={goToEyeCareTips}
                            />
                        </div>
                    </div>

                    <div className={E('swipeable-2')}>
                        <div className={E('bear')}>
                            <img src={babyBear} className={E("bear__body")} alt="baby-bear" />
                            <div className={E("bear__speech-container")}>
                                <div className={E("bear__speech-wrapper")}>
                                    {/* <img src={speechBubble} alt="speech-bubble"/> */}
                                    <div className={E("bear__speech")}>
                                        <p>Hi {name}! Let's read a story!</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>
                            <p>Take a test in between the story</p>
                        </div>

                        <div>
                            <Button
                                text="Start Reading"
                                type="simple"
                                outlined={false}
                                icon="arrow-forward-blue"
                                click={goToStory}
                            />
                        </div>
                    </div>
                    <div className={E('swipeable-3')}>
                        <div className={E('bear')}>
                            <img src={ozzyBear} className={E("bear__body")} alt="baby-bear-reading" />
                        </div>

                        <div>
                            <p>Look for the nearest eye care practitioner</p>
                        </div>

                        <div>
                            <Button
                                text="Search Now"
                                type="simple"
                                outlined={false}
                                icon="arrow-forward-orange"
                                click={goToClinicSearch}
                            />
                        </div>
                    </div>
                </SwipeableViews>

                <div>
                    <Navigation page={routes.dashboard.id} pageFrom={routes.dashboard} />
                </div>

                <Modal show={showWelcomeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                    <div className="badge">
                        <div>
                            <h1>Congratulations!</h1> <br />
                            <caption>You have earned the <p>First Timer</p> badge</caption>
                        </div>
                        <Badge name="welcome-badge" />

                        <Button
                            text="Claim Badge"
                            type="primary"
                            outlined={false}
                            click={() => claimWelcomeBadgeOnClick()}
                        />
                    </div>
                </Modal>

            </div>

        </div>
    )

}

export default withAnimationTransition(Dashboard, {
    entrance: 'FADE_IN'
});
