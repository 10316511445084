import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userSlice, { getUser } from '../../store/slices/user-slice';
import badgesSlice, { getBadges } from '../../store/slices/badges-slice';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import ageOptions from '../../constants/age.constants';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '../../components/ui/Button';
import Modal from '../../components/Modal';
import Badge from '../../components/badge/Badge';
import SwipeableViews from 'react-swipeable-views';

import homeIcon from '../../assets/svg/icons/home-icon.svg';
import backIcon from '../../assets/svg/icons/arrow-back-white.svg';
import deleteIcon from '../../assets/svg/icons/delete-icon.svg';
import ozzyBody from '../../assets/svg/ozzy-bear/ozzy-body.svg';
import ozzyLeftHand from '../../assets/svg/ozzy-bear/ozzy-left-hand.svg';
import ozzyRightHand from '../../assets/svg/ozzy-bear/ozzy-right-hand.svg';
import plank from '../../assets/images/Plank@2x.png';
import babyBear from '../../assets/svg/baby-bear/baby-bear.svg';
import eyeGlasses from '../../assets/svg/baby-bear/eye-glasses.svg';
import ozzyWelcome from '../../assets/svg/ozzy-bear/ozzy-bear-with-glasses.svg';
import avatarGirl1NoGlasses from '../../assets/svg/avatar/avatar-girl-01-no-glasses.svg';
import avatarGirl2NoGlasses from '../../assets/svg/avatar/avatar-girl-02-no-glasses.svg';
import avatarGirl3NoGlasses from '../../assets/svg/avatar/avatar-girl-03-no-glasses.svg';
import avatarBoy1NoGlasses from '../../assets/svg/avatar/avatar-boy-01-no-glasses.svg';
import avatarBoy2NoGlasses from '../../assets/svg/avatar/avatar-boy-02-no-glasses.svg';
import avatarBoy3NoGlasses from '../../assets/svg/avatar/avatar-boy-03-no-glasses.svg';
import avatarGirl1WithGlasses from '../../assets/svg/avatar/avatar-girl-01-with-glasses.svg';
import avatarGirl2WithGlasses from '../../assets/svg/avatar/avatar-girl-02-with-glasses.svg';
import avatarGirl3WithGlasses from '../../assets/svg/avatar/avatar-girl-03-with-glasses.svg';
import avatarBoy1WithGlasses from '../../assets/svg/avatar/avatar-boy-01-with-glasses.svg';
import avatarBoy2WithGlasses from '../../assets/svg/avatar/avatar-boy-02-with-glasses.svg';
import avatarBoy3WithGlasses from '../../assets/svg/avatar/avatar-boy-03-with-glasses.svg';
import evfLogo from '../../assets/images/evf-logo-black.png';
import { useParams } from 'react-router-dom';


interface Props {

}

const UserInfo: React.FC<Props> = () => {
    // VARIABLES

    const params = useParams(); //getting params for users scanning qr code
    const entry = Object.keys(params).length > 0 ? Object.entries<string>(params)[0] : "";
    const userCode: string = entry !== "" ? entry[1] : "";

    const [B, E] = useBEM(routes.userinfo.id);
    const goto = useGoto();
    const dispatch = useDispatch();
    const [startAnimation, setStartAnimation] = useState(false); //animation for delete icon and next button
    const [showWarning, setShowWarning] = useState(false);
    const [emailWarning, setEmailWarning] = useState(false);
    const [showTocWarning, setShowTocWarning] = useState(false);
    const [showBdateWarning, setShowBdateWarning] = useState(false);
    const [nextNameButtonDisabled, setNextNameButtonDisabled] = useState(true);
    const [nextBdateButtonDisabled, setBdateButtonDisabled] = useState(true);
    const [emailButtonDisabled, setEmailButtonDisabled] = useState(true);
    const [tocSubmitDisabled, setTocSubmitDisabled] = useState(true);
    const [isChecked, setIsChecked] = useState(false)
    const [tocDisplay, setTocDisplay] = useState(true);
    const [showFinishButton, setShowFinishButton] = useState(false);
    const [finishButtonDisabled, setFinishButtonDisabled] = useState(true);
    const [connect] = useIndexedDB();
    const { id, name, age, glasses, avatar, progress, badgesEarned } = useSelector(getUser);
    const { profileBadge, goodEyeSightBadge, glassesBadge, avatarBadge } = useSelector(getBadges);
    const [swipeableIndex, setSwipeableIndex] = useState<number>(0);
    const [id_, setID] = useState<string>('');
    const [name_, setName] = useState<string>('');
    const [email_, setEmail] = useState<string>('');
    const [age_, setAge] = useState<number>(5);
    const [bdate_, setBdate] = useState<string>('')
    const [glasses_, setGlasses] = useState<boolean>(false);
    const [progress_, setProgress] = useState<number>(20);
    const [badgesEarned_, setBadgesEarned] = useState<number>(3);
    const [goodEyeSightBadgeClaimed, setGoodEyeSightBadge] = useState(goodEyeSightBadge);
    const [glassesBadgeClaimed, setGlassesBadge] = useState(glassesBadge);
    const [profileBadgeClaimed, setProfileBadgeClaimed] = useState(profileBadge);
    const [avatarBadgeClaimed, setAvatarBadgeClaimed] = useState(avatarBadge);
    const [avatar_, setAvatar] = useState<number>(1);
    const [showGlassesModal, setShowGlassesModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showAvatarModal, setShowAvatarModal] = useState(false);
    const [avatarOptions, setAvatarOptions] = useState<Array<{ id: number, name: string, selected: boolean }>>([
        { id: 1, name: "girl1WithGlasses", selected: false },
        { id: 2, name: "girl1NoGlasses", selected: false },
        { id: 3, name: "girl2WithGlasses", selected: false },
        { id: 4, name: "girl2NoGlasses", selected: false },
        { id: 5, name: "girl3WithGlasses", selected: false },
        { id: 6, name: "girl3NoGlasses", selected: false },
        { id: 7, name: "boy1WithGlasses", selected: false },
        { id: 8, name: "boy1NoGlasses", selected: false },
        { id: 9, name: "boy2WithGlasses", selected: false },
        { id: 10, name: "boy2NoGlasses", selected: false },
        { id: 11, name: "boy3WithGlasses", selected: false },
        { id: 12, name: "boy3NoGlasses", selected: false },
    ]);
    const [notif_, setNotif] = useState<Array<{ id: number, description: string, activity: string, new: boolean }>>([
        { id: 1, description: "Welcome to Ozzy App! Congratulations on winning your very first badge! Continue to test your eyes, learn about eye care tips and follow Baby Bear's eye check journey!", activity: "First time use of app", new: true },
        { id: 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    // HOOKS
    useEffect(() => {
        connect();
        checkID();
    }, []);

    useEffect(() => {
        if (userCode !== "" && userCode !== null && userCode !== undefined) {
            localStorage.clear();
            localStorage.setItem("userCode", userCode);//save code from qr code to localstorage
            setID(userCode);
        } else {
            if (localStorage.getItem("persistantState") !== null) gotoDashboard();
        }
    }, []);

    const gotoDashboard = () => {
        goto.routeWithTransition(
            routes.userinfo,
            routes.dashboard,
            'SLIDE_DOWN',
            500
        );
    }

    // METHODS
    const checkID = () => {
        // if (!id || id === '') return setID(String(Math.floor(100000 + Math.random() * 900000)));
        // if (id) setID(id);
        // if (name) { setName(name); setStartAnimation(true); setNextNameButtonDisabled(false); };
        if (name) { setStartAnimation(true); setNextNameButtonDisabled(true); };
        if (age) setAge(age);
        if (glasses) setGlasses(glasses);
        if (avatar) avatarOnClick(avatar);
        if (progress) setProgress(progress);
        if (badgesEarned) setBadgesEarned(badgesEarned);
    }

    const handleDecrementSwipeableIndex = () => {
        setSwipeableIndex(prevIndex => prevIndex - 1);
    }

    const handleIncrementSwipeableIndex = () => {
        if (nextNameButtonDisabled) {
            setShowWarning(true);
        } else {
            setSwipeableIndex(prevIndex => prevIndex + 1)
        }
    }

    const nameOnChange = (event: any) => {
        let strName = event.target.value;
        setName(strName);
        // nameButtonToggleDisable();
        setShowWarning(false);
        if (strName === '') return setStartAnimation(false);
        setStartAnimation(true);
    }

    const nameButtonToggleDisable = () => {
        if (name_ !== '' && id_ !== '') {
            setNextNameButtonDisabled(false)
        } else if (name_ === '' || id_ === '') {
            setNextNameButtonDisabled(true);
        }
    }

    const idOnChange = (event: any) => {
        let strId = event.target.value;
        setID(strId);
        // nameButtonToggleDisable();
        setShowWarning(false);
    }

    const handleTocCheck = () => {
        setIsChecked((prevState) => !prevState);
    }

    useEffect(() => {
        if (isChecked) {
            setTocSubmitDisabled(false);
        } else {
            setTocSubmitDisabled(true);
        }
    }, [isChecked]);

    const handleSubmit = () => {
        if (tocSubmitDisabled) {
            setShowTocWarning(true);
        } else {
            setTocDisplay(false);
        }
    }

    const emailOnChange = (event: any) => {
        let strEmail = event.target.value;
        setEmail(strEmail);
        // if (!validateEmail(email_)) {
        //     setEmailWarning(true);
        // } else {
        //     setEmailWarning(false);
        // }
    }

    const validateEmail = (email: any) => {
        //var re = /\S+@\S+\.\S+/;
        //var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //var re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,4}$/
        var re = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
        //var re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return re.test(email);
    };

    const emailButtonToggleDisable = () => {
        if (email_ !== '') {
            if (!validateEmail(email_)) {
                setEmailButtonDisabled(true);
            } else {
                setEmailButtonDisabled(false);
                setEmailWarning(false);
            }
        } else {
            setEmailButtonDisabled(true);
        }
    }

    const handleEmail = () => {
        if (emailButtonDisabled) {
            setEmailWarning(true);
        } else {
            handleIncrementSwipeableIndex();
        }
    }

    const validateBdate = (date: any) => {
        var re = /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
        return re.test(date);
    };

    const bdateButtonToggleDisable = () => {
        if (bdate_ !== '') {
            let dateParts = bdate_.split("/");
            let dateObject = dateParts.reverse().join('-');
            let parsedDate = Date.parse(dateObject);
            let today = new Date();
            let parsedToday = Date.parse(today.toDateString());

            if (!validateBdate(bdate_)) {
                setBdateButtonDisabled(true);
            } else if (parsedDate > parsedToday) {
                setBdateButtonDisabled(true);
            } else {
                setBdateButtonDisabled(false);
            }
        } else {
            setBdateButtonDisabled(true);
        }
    }

    const handleBdate = () => {
        if (nextBdateButtonDisabled) {
            setShowBdateWarning(true);
        } else {
            handleIncrementSwipeableIndex();
        }
    }

    useEffect(() => {
        nameButtonToggleDisable();
    }, [name_, id_]);

    useEffect(() => {
        bdateButtonToggleDisable();
    }, [bdate_])

    useEffect(() => {
        emailButtonToggleDisable();
    }, [email_])

    const nameOnClear = () => {
        setName("");
        setStartAnimation(false);
        setNextNameButtonDisabled(true);
    }

    const handleSetProfileOnClick = () => {
        setTimeout(() => {
            if (!profileBadgeClaimed) {
                setShowProfileModal(true);
            } else {
                handleIncrementSwipeableIndex();
            }
        }, 250);
    }

    const claimProfileBadgeOnClick = () => {
        setShowProfileModal(false);
        setProfileBadgeClaimed(true);
        setTimeout(() => {
            handleIncrementSwipeableIndex();
        }, 250);
    }

    const handleOnBackClickCharacterSlide = () => {
        avatarOnClick(0);
        setShowFinishButton(false);
        setFinishButtonDisabled(true);
        handleDecrementSwipeableIndex();
    }

    const glassesYesNoOnClick = (glassesOnOff: boolean) => {
        setTimeout(() => {
            setGlasses(glassesOnOff);
            if (!glassesBadgeClaimed && !goodEyeSightBadgeClaimed) {
                setTimeout(() => {
                    if (glassesOnOff) {
                        setGoodEyeSightBadge(true);
                        setGlassesBadge(false);
                    } else {
                        setGlassesBadge(true);
                        setGoodEyeSightBadge(false);
                    }
                    if (!glassesBadgeClaimed || !goodEyeSightBadgeClaimed) {
                        setShowGlassesModal(true);
                    } else {
                        handleIncrementSwipeableIndex();
                    }
                }, 250);
            } else {
                handleIncrementSwipeableIndex();
            }

        }, 500);
    }

    const claimGlassesBadgeOnClick = () => {
        setShowGlassesModal(false);
        setTimeout(() => {
            handleIncrementSwipeableIndex();
        }, 250);
    }

    const avatarOnClick = (avatarID: number) => {
        setTimeout(() => {
            setAvatarOptions((prevOptions) => {
                prevOptions.forEach(option => {
                    if (option.id === avatarID) {
                        option.selected = true;
                    } else {
                        option.selected = false;
                    }
                })
                return prevOptions;
            });
            setAvatar(avatarID);
            if (avatarID > 0) {
                setShowFinishButton(true);
                setFinishButtonDisabled(false);
            }


        }, 250);
    }

    const claimAvatarBadgeOnClick = () => {
        setShowAvatarModal(false);
        setAvatarBadgeClaimed(true);
        handleIncrementSwipeableIndex();
    }

    const finishOnClick = () => {
        if (swipeableIndex === 4 && !avatarBadgeClaimed) setShowAvatarModal(true);

        let emailVal

        if (!validateEmail(email_)) {
            emailVal = "";
        } else {
            emailVal = email_
        }

        let dateVal

        var dateParts = bdate_.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = dateParts.reverse().join('-');

        dateVal = dateObject;

        console.log("bdate: " + dateVal);


        const userData = {
            name: name_,
            parentEmail: emailVal,
            usesGlassess: glasses_,
            birthDate: dateVal,
            uniqueId: id_,
        }

        console.log(userData);

        fetch('https://ozzy-prd-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
            .then((response) => response.json())
            .then(data => {
                const userID = data.data;
                console.log(userID);

                const user = {
                    id: id_, name: name_, age: age_, glasses: glasses_, userInfoId: userID, avatar: avatar_, progress: progress_, badgesEarned: badgesEarned_,
                }
                const badges = {
                    profileBadge: profileBadgeClaimed, goodEyeSightBadge: !glasses_, glassesBadge: glasses_, avatarBadge: avatarBadgeClaimed
                }
                dispatch(userSlice.actions.setUserData(user));
                dispatch(badgesSlice.actions.setBadgesData(badges));
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        // setTimeout(() => {
        //     setTimeout(() => {
        //         const user = {
        //             id: id_, name: name_, age: age_, glasses: glasses_, avatar: avatar_, progress: progress_, badgesEarned: badgesEarned_,
        //         }
        //         const badges = {
        //             profileBadge: profileBadgeClaimed, goodEyeSightBadge: !glasses_, glassesBadge: glasses_, avatarBadge: avatarBadgeClaimed
        //         }
        //         dispatch(userSlice.actions.setUserData(user));
        //         dispatch(badgesSlice.actions.setBadgesData(badges));
        //     }, 250);
        // }, 250);
    }

    const letsGetStartedOnClick = () => {
        if (progress_ && progress_ < 40) dispatch(userSlice.actions.saveNotifications({ notification: notif_ }));
        setTimeout(() => {
            if (localStorage.getItem("userCode") !== null) {
                goto.routeWithTransition(
                    routes.userinfo,
                    routes.selectmode,
                    'SLIDE_LEFT',
                    500
                );
            } else {
                goto.routeWithTransition(
                    routes.userinfo,
                    routes.dashboard,
                    'SLIDE_LEFT',
                    500
                );
            }
        }, 250);
    }

    return (
        <div className={B() + ' page bg-blue'}>

            <div className="version-text">
                version 3.3
            </div>

            <SwipeableViews enableMouseEvents={false} disabled style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={swipeableIndex}>
                {/* swipeableIndex 0 */}
                <div className="flex-col swipeable-container">
                    <div className="flex-row title-wrapper">
                        <img src={homeIcon} className={E('home-icon')} alt="home-icon" />
                        <h1>What's your full name?</h1>
                    </div>

                    <div className={E("bear")}>
                        <img className={E("bear__body")} src={ozzyBody} alt="ozzy-bear" />
                        <div className={E("bear__input-container")}>
                            <div className={E("bear__input-wrapper")}>
                                <img className="bear__hands bear__hands--left" src={ozzyLeftHand} alt="ozzy-left-hand" />
                                <input className={E("bear__input")} type="text" value={name_} placeholder="Last name, First name Middle name" onChange={nameOnChange} id="nameInput" />
                                <button className={E("bear__close", [startAnimation ? "animate" : "animate-out"])} onClick={nameOnClear}>
                                    <img className={E("delete__icon")} src={deleteIcon} alt="close" />
                                </button>
                                <img className="bear__hands bear__hands--right" src={ozzyRightHand} alt="ozzy-right-hand" />

                                <div className="id-container">
                                    <img src={plank} alt="plank" className='id-input-bg' />
                                    {userCode !== "" && userCode !== null && userCode !== undefined ?
                                        <input className="bear__idInput" type="text" value={id_} placeholder="Unique ID" id="idInput" onChange={idOnChange} readOnly />
                                        :
                                        <input className="bear__idInput" type="text" value={id_} placeholder="Unique ID" id="idInput" onChange={idOnChange} />
                                    }

                                </div>

                                {showWarning &&
                                    <div className="warning-container">
                                        Required field
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="button-wrapper">
                        <Button
                            text="Next"
                            type="secondary"
                            outlined={false}
                            click={() => handleIncrementSwipeableIndex()}
                            className={E("button--name", [nextNameButtonDisabled ? "btn-disabled" : "btn-enabled"])}
                        />
                    </div>
                </div>

                {/* swipeableIndex 1 */}
                <div className="flex-col swipeable-container">
                    <div className="flex-row title-wrapper bday-slide">
                        <img src={backIcon} alt="back-icon" onClick={() => handleDecrementSwipeableIndex()} />
                        <h1>Date of birth</h1>
                    </div>

                    <div className={E("bear")}>
                        <img className={E("bear__body")} src={ozzyBody} alt="ozzy-bear" />
                        <div className={E("bear__input-container-age")}>
                            <div className={E("bear__input-wrapper")}>
                                <img className="bear__hands bear__hands--left" src={ozzyLeftHand} alt="ozzy-left-hand" />
                                {/* <Select
                                    className={E("bear__input")}
                                    value={age_}
                                    onChange={event => setAge(event.target.value as number)}
                                    disableUnderline>

                                    {ageOptions.map((age, index) => {
                                        return <MenuItem key={index} value={age}>{age}</MenuItem>
                                    })}
                                </Select> */}
                                <input className={E("bear__input")} type="text" placeholder="DD/MM/YYYY" name="bdate" id="bdate" onChange={event => setBdate(event.target.value)} />
                                <img className="bear__hands bear__hands--right" src={ozzyRightHand} alt="ozzy-right-hand" />
                            </div>

                            {showBdateWarning &&
                                <div className="warning-container">
                                    Birth date format should be dd/mm/yyyy
                                </div>
                            }
                        </div>
                    </div>

                    <div className="button-wrapper">
                        <Button
                            text="Next"
                            type="secondary"
                            outlined={false}
                            click={() => handleBdate()}
                            className={E("button--name", [nextBdateButtonDisabled ? "btn-disabled" : "btn-enabled"])}
                        />
                    </div>

                    {/* toc section */}
                    {tocDisplay ? <div className="toc-container">
                        <div className="toc-text-container">
                            <div className="toc-logo">
                                <img src={evfLogo} className="evf-logo" alt="evf-logo" />
                            </div>

                            <div className="toc-title">
                                Privacy Notice
                            </div>

                            <div className="toc-text">
                                <p className="toc-text-title">ABOUT THE ONESIGHT ESSILORLUXOTTICA FOUNDATION</p>
                                <p className="toc-text-paragraph">
                                    The OneSight EssilorLuxottica Foundation (formerly Essilor Social Impact) is a French registered charitable organization (endowment fund) reflecting the commitment and values of EssilorLuxottica to eliminate uncorrected poor vision in a generation. It was rebranded in 2022 to bring together EssilorLuxottica’s philanthropic, advocacy actions and investments including: Vision for Life, Essilor Vision Foundations in North America, India, Southeast Asia and China, Fondazione Salmoiraghi & Viganò in Italy as well as the Company’s long term global partners OneSight and the Vision Impact Institute. It is headquartered at 147 rue de Paris, 94220 Charenton-Le-Pont, France. https://onesight.essilorluxottica.com/.
                                </p>

                                <p className="toc-text-title">PRIVACY INFORMATION NOTICE</p>
                                <p className='toc-text-paragraph'>
                                    This Privacy Information Notice is applicable to Foundation. We remain fully responsible of the collection and processing of Personal Data and is the data collector (or, as the case may be, the data processor) of the data we collect and process. The purpose of this Privacy Policy is to inform you of what personal data We collect, why We collect, use, disclose and process it. Your personal data means any data, whether true or not, about an individual who can be identified: (a) from that data; or (b) from that data and other information to which the organisation has or is likely to have access. As part of our initiative to improve quality of our products, eyecare screenings and our technology and provide eyecare services and products to participants, we are collecting, using, processing, disclosing and transferring personal data derived during eye screening from you, a participant of the initiative (“Participant”), for analysis of eyecare needs by location. As a Participant for the eye screening initiative, your personal data shall be collected and de–identified prior to sending to us, so it could no longer identify the individual the data is collected from. The personal data collected will not be sold, transferred and/or provided for commercial use. Please take the time to read this Privacy Notice carefully. It is very important that you fully understand how We are processing your personal data and how We are protecting your privacy. If you have any questions regarding our use of your personal data, please contact us as set out below.
                                </p>

                                <p className="toc-text-title">WHAT PERSONAL DATA WE COLLECT AND HOW WE USE PERSONAL DATA WE COLLECT</p>
                                <p className="toc-text-paragraph">
                                    We will only be able to collect your personal data if you and/or someone (i.e. an individual or entity) you authorised voluntarily submit the information to us. If you choose not to submit your personal data to us or subsequently, you may withdraw your consent to our use of your personal information. We may not be able to provide you with our products and/or services. You may access and update your personal data submitted by contacting us.
                                    <br /><br />
                                    We will collect the following categories of your personal data:
                                    <ul>
                                        <li>User's Full Name </li>
                                        <li>Age</li>
                                        <li>Province and country of location</li>
                                        <li>Eye screening results (eg visual acuity, color blindness, distance, light sensitivity, near vision, astigmatism, etc.) </li>
                                    </ul>
                                    We only use your de-identified personal data within the limits authorized by the laws and regulations. We do not make any automated decisions solely on automatic processing which may produce legal consequences concerning you, or which may significantly affect you.
                                </p>

                                <p className="toc-text-title">HOW LONG WE KEEP PERSONAL DATA WE COLLECT</p>
                                <p className="toc-text-paragraph">
                                    The retention periods for your personal data depend on the purpose for which We collect this data is processed for. We will retain your personal data only for as long as (i) necessary to carry out each of the Purposes, (ii) you have consented to, and/or (iii) required by applicable laws.
                                </p>

                                <p className="toc-text-title">PERSONAL DATA WE SHARE</p>
                                <p className="toc-text-paragraph">
                                    We inform you that we may share your personal data with companies, organizations of the Foundation, other companies or individuals outside of our company.
                                    <br /><br />
                                    For recipients located in France, the European Commission has decided that this country ensures an adequate level of protection by reason of its domestic law or of the international commitments it has entered to.
                                    <br /><br />
                                    For recipients located in USA and Singapore, there is no adequacy decision by the European Commission for such country; this means that the level of data protection in such country is not comparable with the level of data protection in the European Union. Therefore, we use as an appropriate safeguard to secure your personal data the EU Model Clause. You can request a copy of it by contact us at the contact details below.
                                </p>

                                <p className="toc-text-title">HOW WE PROTECT PERSONAL DATA</p>
                                <p className="toc-text-paragraph">
                                    We have measures in place to protect your personal data against unauthorized access, use, or disclosure, including without limitation:
                                    <ul>
                                        <li>We implement and maintain sophisticated technical measures to ensure that your personal data is recorded and processed in complete confidentiality and security.</li>
                                        <li>We implement and maintain appropriate restrictions on access to your personal data, and monitoring of the access, use, and transfer of personal data. </li>
                                        <li>All of our employees who have access to your personal data are required to enter into non-disclosure or similar agreements, which imposes obligations on them to comply with our data privacy and confidentiality requirements.</li>
                                        <li>We require any business partners and third party service providers with whom We may share your personal data to comply with any applicable data privacy and confidentiality requirements. </li>
                                        <li>We provide data privacy training on a regular basis to our employees and third parties who have access to personal data.</li>
                                    </ul>
                                    Though We will take reasonable measures to protect the personal data, no method of transmission over the internet or method of electronic storage is completely secure. As such, We expressly recommend all users of our websites:
                                    <ul>
                                        <li>Keep all passwords and login details used for the website secure, private and confidential, without revealing it, disclosing it or providing it to third parties.</li>
                                        <li>Not to access the website through computers, mobile phones, tablets and other devices in electronic environments that are unsafe, suspicious, open, shared or that in any way may represent transaction risks or facilitate the occurrence of fraud.</li>
                                        <li>Adopt security measures on computers, mobile phones, tablets and other devices used to access the website, such as but not limited to the installation and use of antivirus, antispyware and adware programs.</li>
                                    </ul>
                                    Notwithstanding the aforesaid, to the extent permitted by law, We will not be liable for:
                                    <ul>
                                        <li>The misuse of any website user password and any loss of data arising therefrom; including but not limited to, in cases of loss, theft or misplacement by any means or form. Improper use of the website access data will be the exclusive responsibility of a website user, including liability for damages due to improper use by third parties, and We shall be exempt from any liability for these acts. </li>
                                        <li>Province and country of location</li>
                                        <li>Eye screening results (eg diopter, subjective power, eye pressure and etc.) </li>
                                        <li>Links to and any content contained in websites it does not own; and also any damage, direct or indirect, suffered as a result of accessing such websites even if linked to our website (regardless of cause, origin, nature or consequences).</li>
                                    </ul>
                                </p>

                                <p className="toc-text-title">WHAT RIGHTS YOU HAVE</p>
                                <p className="toc-text-paragraph">
                                    Under applicable data protection laws and regulations, you have a right:
                                    <ul>
                                        <li>Of access to, rectification of, and/or erasure (where required by law) of your personal data; </li>
                                        <li>To restrict or object, in whole or in part, to its processing (where required by law); </li>
                                        <li>To tell us that you do not wish to receive marketing information;</li>
                                        <li>To the extent our processing of your personal data is based upon your consent, to withdraw your consent, without affecting the lawfulness of our processing based on your consent before its withdrawal; and</li>
                                        <li>the right to request your personal data portability (where applicable), i.e. that the personal data you have provided to us be returned to you or transferred to the person of your choice, in a structured, commonly used and machine-readable format without hindrance from us and subject to your confidentiality obligations.</li>
                                    </ul>
                                    Please note, however, that certain personal data may be exempt from the above-mentioned rights pursuant to applicable data privacy or other laws and regulations.
                                    <br /><br />
                                    We are committed to enable you exercising your rights: to do so, you can contact us. Please provide us with the following information, so that We can take your request with all due consideration:
                                    <ul>
                                        <li>Your name and surname, and any other identification information which We may request from you; </li>
                                        <li>Your specific request (description of the rights you wish to exercise); and </li>
                                        <li>The date of the application and your signature (if you sent your application by postal mail). </li>
                                    </ul>
                                    If you do not get satisfaction by contacting us, you can also lodge a complaint about our processing of your personal data with a data protection authority.
                                </p>

                                <p className="toc-text-title">CONTACT</p>
                                <p className="toc-text-paragraph">
                                    If you have any question regarding our use of your personal data, you can contact us as set out below:
                                    <br /><br />
                                    Direction à la Protection des Données ESSILOR INTERNATIONAL SAS,
                                    <br />
                                    147 Rue de Paris
                                    <br />
                                    94220 Charenton Le Pont France
                                    <br />
                                    Email: GlobalDPO@essilor.com
                                </p>

                                <p className="toc-text-title">CHANGES TO THIS PRIVACY NOTICE</p>
                                <p className="toc-text-paragraph">
                                    We regularly review our Privacy Notice, in particular to make it compliant with new laws and regulations regarding data protection. The continued use of our website and services constitutes acknowledge and acceptance of such revised terms to this Privacy Notice from time to time. But, even if this Privacy Notice may change from time to time, we will not reduce your rights under this Privacy Notice without your explicit consent.
                                </p>

                                <p className="toc-text-title">PARTICIPANT’S INFORMED CONSENT</p>
                                <p className="">
                                    <ul>
                                        <li>I declare that I have been informed about the personal data collection, its goals, its possible constraints and what is expected from me.</li>
                                        <li>I had enough time to think about it</li>
                                        <li>I am satisfied with the answers given to my queries, and have on my own free will agreed to participate</li>
                                        <li>I understand that I may be contacted by the Foundation to provide further consent for future eyecare initiatives and analysis</li>
                                        <li>I understand my personal data will be collected during my participation in the initiative by the Foundation to improve quality of products, eyecare screenings and technology</li>
                                        <li>I understand my personal data will be treated and shared according to conditions described in this Privacy Notice</li>
                                    </ul>
                                    I consent to the processing of my personal data under the terms described in the Privacy Notice. I also agree to the transfer and processing of my personal data to the subsidiaries of ESSILORLUXOTTICA Group in countries of their presence.
                                    <br /><br />
                                    Last modified: August 2022
                                </p>
                            </div>

                            <div className="toc-checkbox">
                                <div className="checkbox-container">
                                    <input type="checkbox" name="toc-checkbox" id="toc-checkbox" defaultChecked={isChecked} onChange={handleTocCheck} />
                                </div>
                                <div className="checkbox-text">
                                    Check here to indicate that you have read and agree to the terms of the OneSight EssilorLuxottica Foundation
                                </div>
                            </div>

                            {showTocWarning &&
                                <div className="warning-container">
                                    Please tick the checkbox to proceed
                                </div>
                            }

                            <div className="toc-button">
                                <Button
                                    text="Submit"
                                    type="secondary"
                                    outlined={false}
                                    click={() => handleSubmit()}
                                    className={tocSubmitDisabled ? "btn-disabled" : "btn-enabled"}
                                />
                            </div>
                        </div>
                    </div> : <></>}

                </div>

                {/* swipeableIndex 2 */}
                <div className="flex-col swipeable-container">
                    <div className="flex-row title-wrapper">
                        <img src={homeIcon} className={E('home-icon')} alt="home-icon" />
                        <h1>Please enter your parent or guardian's email.</h1>
                    </div>

                    <div className={E("bear")}>
                        <img className={E("bear__body")} src={ozzyBody} alt="ozzy-bear" />
                        <div className={E("bear__input-container-email")}>
                            <div className={E("bear__input-wrapper")}>
                                <img className="bear__hands bear__hands--left" src={ozzyLeftHand} alt="ozzy-left-hand" />
                                <input className={E("bear__input")} type="text" value={email_} placeholder="Email" onChange={emailOnChange} id="emailInput" />
                                <img className="bear__hands bear__hands--right" src={ozzyRightHand} alt="ozzy-right-hand" />
                            </div>
                        </div>
                        {emailWarning &&
                            <div className="warning-container">
                                Invalid email
                            </div>
                        }
                    </div>

                    <div className="button-wrapper">
                        <Button
                            text="Next"
                            type="secondary"
                            outlined={false}
                            click={() => handleEmail()}
                            className={E("button--name", [emailButtonDisabled ? "btn-disabled" : "btn-enabled"])}
                        />
                    </div>
                </div>

                {/* swipeableIndex 3 */}
                <div className="flex-col swipeable-container">
                    <div className="flex-row title-wrapper">
                        <img src={backIcon} alt="back-icon" onClick={() => handleDecrementSwipeableIndex()} />
                        <h1>Do you wear glasses?</h1>
                    </div>

                    <div className={E("bear--kid")}>
                        <img src={babyBear} className={E("bear__body")} alt="baby-bear" />
                        <img src={eyeGlasses} className={E("bear__glasses", [glasses_ ? 'animate' : 'animate-out'])} alt="eye-glasses" />
                    </div>

                    <div className="button-wrapper-yes-no">
                        <div>
                            <Button
                                text="Yes"
                                type="primary-small"
                                outlined={false}
                                click={() => glassesYesNoOnClick(true)}
                            />
                        </div>

                        <div>
                            <Button
                                text="No"
                                type="secondary-small"
                                outlined={false}
                                click={() => glassesYesNoOnClick(false)}
                            />
                        </div>
                    </div>
                </div>

                {/* swipeableIndex 4 */}
                <div className="flex-col swipeable-container">
                    <div className="flex-row title-wrapper">
                        <img src={backIcon} alt="back-icon" onClick={() => handleOnBackClickCharacterSlide()} />
                        <h1>Choose your character</h1>
                    </div>

                    <div className={E("avatar-container")}>

                        {glasses_ ?
                            <>
                                <div className={E("avatar-item", [avatarOptions[0].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarGirl1WithGlasses} alt="avatar-girl-01" onClick={() => avatarOnClick(1)} />
                                </div>

                                <div className={E("avatar-item--boy", [avatarOptions[6].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy1WithGlasses} alt="avatar-boy-01" onClick={e => avatarOnClick(7)} />
                                </div>

                                <div className={E("avatar-item", [avatarOptions[2].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarGirl2WithGlasses} alt="avatar-girl-02" onClick={e => avatarOnClick(3)} />
                                </div>

                                <div className={E("avatar-item--boy", [avatarOptions[8].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy2WithGlasses} alt="avatar-boy-02" onClick={e => avatarOnClick(9)} />
                                </div>

                                <div className={E("avatar-item-girl3", [avatarOptions[4].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarGirl3WithGlasses} alt="avatar-girl-03" onClick={e => avatarOnClick(5)} />
                                </div>

                                <div className={E("avatar-item--boy", [avatarOptions[10].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy3WithGlasses} alt="avatar-boy-03" onClick={e => avatarOnClick(11)} />
                                </div>
                            </>
                            :
                            <>
                                <div className={E("avatar-item", [avatarOptions[1].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarGirl1NoGlasses} alt="avatar-girl-01" onClick={e => avatarOnClick(2)} />
                                </div>

                                <div className={E("avatar-item--boy", [avatarOptions[7].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy1NoGlasses} alt="avatar-boy-01" onClick={e => avatarOnClick(8)} />
                                </div>

                                <div className={E("avatar-item", [avatarOptions[3].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarGirl2NoGlasses} alt="avatar-girl-02" onClick={e => avatarOnClick(4)} />
                                </div>

                                <div className={E("avatar-item--boy", [avatarOptions[9].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy2NoGlasses} alt="avatar-boy-02" onClick={e => avatarOnClick(10)} />
                                </div>

                                <div className={E("avatar-item-girl3", [avatarOptions[5].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarGirl3NoGlasses} alt="avatar-girl-03" onClick={e => avatarOnClick(6)} />
                                </div>

                                <div className={E("avatar-item--boy", [avatarOptions[11].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy3NoGlasses} alt="avatar-boy-03" onClick={e => avatarOnClick(12)} />
                                </div>
                            </>
                        }

                    </div>

                    <div className="button-wrapper">
                        <Button
                            text="Finish"
                            type="secondary"
                            outlined={false}
                            click={() => finishOnClick()}
                            disabled={finishButtonDisabled}
                            className={E("button--finish", [showFinishButton ? "animate" : "animate-out"])}
                        />
                    </div>
                </div>

                {/* swipeableIndex 5 */}
                <div className="flex-col swipeable-container">
                    <div className="flex-row title-wrapper">
                        <img src={backIcon} alt="back-icon" onClick={() => handleDecrementSwipeableIndex()} />
                        <h1>Welcome, {name}</h1>
                    </div>

                    <div className={E("bear--kid")}>
                        <img src={ozzyWelcome} className={E("bear__body")} alt="baby-bear" />
                    </div>

                    <div className="button-wrapper">
                        <Button
                            text="Let's get started!"
                            type="primary"
                            outlined={false}
                            click={() => letsGetStartedOnClick()}
                        />
                    </div>
                </div>
            </SwipeableViews>


            <Modal show={showProfileModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>Nice work!</h1> <br />
                        <caption>You have earned the <p>Profile</p> badge</caption>
                    </div>
                    <Badge name="profile-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimProfileBadgeOnClick()}
                    />
                </div>
            </Modal>

            <Modal show={showGlassesModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>{glasses_ ? "Look at those glasses!" : "You have good eyes!"}</h1> <br />
                        <caption>You have earned the <p>{glasses_ ? "Eyeglasses" : "Good  Eyesight"}</p> badge</caption>
                    </div>
                    <Badge name={glasses_ ? "glasses-badge" : "good-eyesight-badge"} />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimGlassesBadgeOnClick()}
                    />
                </div>
            </Modal>

            <Modal show={showAvatarModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>Looking good!</h1> <br />
                        <caption>You have earned the <p>Character</p> badge</caption>
                    </div>
                    <Badge name="avatar-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimAvatarBadgeOnClick()}
                    />
                </div>
            </Modal>


        </div>
    )

}

export default withAnimationTransition(UserInfo, {
    entrance: 'FADE_IN'
});
