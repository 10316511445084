import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { Line } from 'rc-progress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ageOptions from '../../constants/age.constants';
import userSlice, { getUser } from '../../store/slices/user-slice';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import Button from '../../components/ui/Button';

import Avatar from '../../components/avatar/Avatar';
import Modal from '../../components/Modal';
import Navigation from '../../components/navigation/Navigation';
import backIcon from '../../assets/svg/icons/arrow-back-white.svg';
import deleteIcon from '../../assets/svg/icons/delete-icon.svg';
import bronzeStar from '../../assets/svg/badges/bronze-star.svg';
import silverStar from '../../assets/svg/badges/silver-star.svg';
import goldStar from '../../assets/svg/badges/gold-star.svg';
import editIcon from '../../assets/svg/icons/edit-icon.svg';
import avatarGirl1NoGlasses from '../../assets/svg/avatar/avatar-girl-01-no-glasses.svg';
import avatarGirl2NoGlasses from '../../assets/svg/avatar/avatar-girl-02-no-glasses.svg';
import avatarGirl3NoGlasses from '../../assets/svg/avatar/avatar-girl-03-no-glasses.svg';
import avatarBoy1NoGlasses from '../../assets/svg/avatar/avatar-boy-01-no-glasses.svg';
import avatarBoy2NoGlasses from '../../assets/svg/avatar/avatar-boy-02-no-glasses.svg';
import avatarBoy3NoGlasses from '../../assets/svg/avatar/avatar-boy-03-no-glasses.svg';
import avatarGirl1WithGlasses from '../../assets/svg/avatar/avatar-girl-01-with-glasses.svg';
import avatarGirl2WithGlasses from '../../assets/svg/avatar/avatar-girl-02-with-glasses.svg';
import avatarGirl3WithGlasses from '../../assets/svg/avatar/avatar-girl-03-with-glasses.svg';
import avatarBoy1WithGlasses from '../../assets/svg/avatar/avatar-boy-01-with-glasses.svg';
import avatarBoy2WithGlasses from '../../assets/svg/avatar/avatar-boy-02-with-glasses.svg';
import avatarBoy3WithGlasses from '../../assets/svg/avatar/avatar-boy-03-with-glasses.svg';
import emojiHappy from '../../assets/svg/emoji/emoji-happy.svg';



interface Props {

}

const Profile : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM(routes.profile.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);
    const dispatch = useDispatch();
    const { name, lastName, age, avatar, glasses, badgesEarned, progress } = useSelector(getUser);
    const [swipeableIndex, setSwipeableIndex] = useState<number>(0);
    const [name_, setName] = useState(name);
    const [deleteNameIconAnimation, setDeleteNameIconAnimation] = useState(false);
    const [lastName_, setLastName] = useState(lastName);
    const [deleteLastNameIconAnimation, setDeleteLastNameIconAnimation] = useState(false);
    const [age_, setAge] = useState(age);
    const [glasses_, setGlasses] = useState(glasses);
    const [avatar_, setAvatar] = useState<any>(avatar);
    const [showSaveModal, setShowSaveModal] = useState(false);

    const [avatarOptions, setAvatarOptions] = useState<Array<{id: number, name: string, selected: boolean}>>([
        { id: 1, name: "girl1WithGlasses", selected: false},
        { id: 2, name: "girl1NoGlasses", selected: false},
        { id: 3, name: "girl2WithGlasses", selected: false},
        { id: 4, name: "girl2NoGlasses", selected: false},
        { id: 5, name: "girl3WithGlasses", selected: false},
        { id: 6, name: "girl3NoGlasses", selected: false},
        { id: 7, name: "boy1WithGlasses", selected: false},
        { id: 8, name: "boy1NoGlasses", selected: false},
        { id: 9, name: "boy2WithGlasses", selected: false},
        { id: 10, name: "boy2NoGlasses", selected: false},
        { id: 11, name: "boy3WithGlasses", selected: false},
        { id: 12, name: "boy3NoGlasses", selected: false},
    ]);

    const swipeableStyles = {
        root: {
          padding: '0',
          height: '100%',
          width: '100%'
        },
        slideContainer: {
          padding: '0'
        }
    };

    const swipeableStylesAvatar = {
        root: {
          padding: '0',
          width: '100%'
        }
    };

    // HOOKS
    useEffect(() => {
        connect();
        checkFields();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.profile,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    useEffect(() => {
        avatarOnClick(avatar_);
    }, [avatar_])

    //METHODS
    const goToDashboard = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.profile, 
                routes.dashboard,
                'SLIDE_RIGHT',
                500
            );
        }, 250);
    }

    const goToBadges = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.profile, 
                routes.mybadges,
                'SLIDE_RIGHT',
                500
            );
        }, 250);
    }

    const goToMyResults = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.profile, 
                routes.myresults,
                'SLIDE_RIGHT',
                500
            );
        }, 250);
    }

    const checkFields = () => {
        if (name) {setName(name); setDeleteNameIconAnimation(true); };
        if (lastName) {setLastName(lastName); setDeleteLastNameIconAnimation(true); };
        if (glasses) setGlasses(glasses);
        if (avatar) avatarOnClick(avatar); 
    }


    const handleDecrementSwipeableIndex = () => {
        setSwipeableIndex(prevIndex => prevIndex - 1);
    }

    const handleIncrementSwipeableIndex = () => {
        setSwipeableIndex(prevIndex => prevIndex + 1)
    }

    const avatarOnClick = (avatarID: number) => {
        setTimeout(() => {
            setAvatarOptions((prevOptions) => {
                prevOptions.forEach(option => {
                    if (option.id === avatarID) {
                        option.selected = true;
                    } else {
                        option.selected = false;
                    }
                })
                return prevOptions;
            });
            setAvatar(avatarID);
        }, 250);
    }

    const nameOnChange = (event: any) => {
        let strName = event.target.value;
        setName(strName);
        if (strName === '') return setDeleteNameIconAnimation(false);
        setDeleteNameIconAnimation(true);
    }

    const nameOnClear = () => {
        setName("");
        setDeleteNameIconAnimation(false);
    }

    const lastNameOnChange = (event: any) => {
        let strName = event.target.value;
        setLastName(strName);
        if (strName === '') return setDeleteLastNameIconAnimation(false);
        setDeleteLastNameIconAnimation(true);
    }

    const lastNameOnClear = () => {
        setLastName("");
        setDeleteLastNameIconAnimation(false);
    }

    const handleSaveChangesOnClick = () => {
        if (name_ || name_ !== '') {
            setTimeout(() => {
                setTimeout(() => {
                    const user = {
                        name: name_, lastName: lastName_, age: age_, avatar: avatar_
                    }
                dispatch(userSlice.actions.setUserData(user));
                }, 250);
                setShowSaveModal(true);
            },250)
        }
    }

    const saveModalOnClose = () => {
        setShowSaveModal(false);
    }

    const renderProgressStar = () => {
        if (progress && progress === 100) {
            return <img src={goldStar} alt="gold-star"/>
        } else  if (progress && progress >= 50 ) {
            return <img src={silverStar} alt="silver-star"/>
        } else {
            return <img src={bronzeStar} alt="bronze-star"/>
        }
    }

    const renderAchievementStatus = () => {
        if (progress && progress === 100) {
            return <p>Gold Achiever</p>
        } else  if (progress && progress >= 50 ) {
            return <p>Silver Achiever</p>
        } else {
            return <p>Bronze Achiever</p>
        }
    }

    return(
        <div className={B() + ' page bg-light'}>
            <SwipeableViews enableMouseEvents={false} disabled style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={swipeableIndex}> 
                {/* swipeableIndex 0 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                        
                        <h1>Profile</h1>
                    </div>

                    <div className={E('profile')}>
                        <div className={E('profile-wrapper')}>
                            <div className={E('profile-avatar')}>
                                <Avatar avatar={avatar} />
                            </div>

                            <div className={E('profile-star')}>
                                {renderProgressStar()}
                            </div>
                        </div>

                        <div className={E('profile-name')}>
                            <h1>{name}</h1>
                            <img src={editIcon} alt="edit-icon" onClick={() => handleIncrementSwipeableIndex()}/>
                        </div>

                        <div>
                            {renderAchievementStatus()}
                        </div>
    
                        <Line percent={progress} strokeWidth={2} trailWidth={2} strokeColor="#00A5F2" trailColor='#E5E5E5'/>
                    </div>

                    <div className={E('badges-progress')}>
                        <div className={E('badges-earned')} onClick={() => goToBadges()}>
                            <h2>{badgesEarned}</h2>
                            <caption>Earned Badges</caption>
                        </div>
                        <div className={E('total-progress')} onClick={() => goToMyResults()}>
                            <h2>{progress}%</h2>
                            <caption>Total Progress</caption>
                        </div>
                    </div>
                    
                
                    <div>
                        <Navigation page={routes.profile.id} pageFrom={routes.profile}/>
                    </div>
                </div>

                {/* swipeableIndex 1 */}
                <div className="flex-col">
                    <div className="flex-row title-wrapper"> 
                        <img src={backIcon} alt="back-icon" onClick={() => handleDecrementSwipeableIndex()}/>                        
                        <h1>Edit Profile</h1>
                    </div>

                    <SwipeableViews enableMouseEvents style={swipeableStylesAvatar.root}  slideStyle={swipeableStyles.slideContainer} index={0}> 
                    
                        <div className={E('profile__avatar-container')}>
                            <div className={E('profile__avatar')}>
                                {glasses_ ? 
                                    <>
                                    <div className={E("avatar-item", [avatarOptions[0].selected ? 'selected' : 'not-selected'])}>
                                        <img src={avatarGirl1WithGlasses} alt="avatar-girl-01" onClick={() => avatarOnClick(1)}/>
                                    </div>

                                    <div className={E("avatar-item", [avatarOptions[6].selected ? 'selected' : 'not-selected'])}>
                                        <img src={avatarBoy1WithGlasses} alt="avatar-boy-01" onClick={e => avatarOnClick(7)}/>
                                    </div>

                                    <div className={E("avatar-item", [avatarOptions[2].selected ? 'selected' : 'not-selected'])}>
                                        <img src={avatarGirl2WithGlasses} alt="avatar-girl-02" onClick={e => avatarOnClick(3)}/>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className={E("avatar-item", [avatarOptions[1].selected ? 'selected' : 'not-selected'])}>
                                        <img src={avatarGirl1NoGlasses} alt="avatar-girl-01" onClick={e => avatarOnClick(2)}/>
                                    </div>

                                    <div className={E("avatar-item", [avatarOptions[7].selected ? 'selected' : 'not-selected'])}>
                                        <img src={avatarBoy1NoGlasses} alt="avatar-boy-01" onClick={e => avatarOnClick(8)}/>
                                    </div>

                                    <div className={E("avatar-item", [avatarOptions[3].selected ? 'selected' : 'not-selected'])}>
                                        <img src={avatarGirl2NoGlasses} alt="avatar-girl-02" onClick={e => avatarOnClick(4)}/>
                                    </div>
                                    </>
                                }
                            </div>
                            <div className={E('profile__avatar-breadcrumb-container')}>
                                <div className={E('profile__avatar-breadcrumb-current')}></div>
                                <div className={E('profile__avatar-breadcrumb')}></div>
                                <div className={E('profile__avatar-breadcrumb')}></div>
                            </div>
                         
                        </div>
                       
                        <div className={E('profile__avatar-container')}>
                            <div className={E('profile__avatar')}>
                                {glasses_ ? 
                                <>
                                <div className={E("avatar-item", [avatarOptions[8].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy2WithGlasses} alt="avatar-boy-02" onClick={e => avatarOnClick(9)}/>
                                </div>

                                <div className={E("avatar-item-girl3", [avatarOptions[4].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarGirl3WithGlasses} alt="avatar-girl-03" onClick={e => avatarOnClick(5)}/>
                                </div>

                                <div className={E("avatar-item", [avatarOptions[10].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy3WithGlasses} alt="avatar-boy-03" onClick={e => avatarOnClick(11)}/>
                                </div>
                                </>
                                :
                                <>
                                <div className={E("avatar-item", [avatarOptions[9].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy2NoGlasses} alt="avatar-boy-02" onClick={e => avatarOnClick(10)}/>
                                </div>

                                <div className={E("avatar-item-girl3", [avatarOptions[5].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarGirl3NoGlasses} alt="avatar-girl-03" onClick={e => avatarOnClick(6)}/>
                                </div>

                                <div className={E("avatar-item", [avatarOptions[11].selected ? 'selected' : 'not-selected'])}>
                                    <img src={avatarBoy3NoGlasses} alt="avatar-boy-03" onClick={e => avatarOnClick(12)}/>
                                </div>
                                </>
                                }
                            </div>
                            <div className={E('profile__avatar-breadcrumb-container')}>
                                <div className={E('profile__avatar-breadcrumb')}></div>
                                <div className={E('profile__avatar-breadcrumb')}></div>
                                <div className={E('profile__avatar-breadcrumb-current')}></div>
                            </div>
                         
                        </div>
                    </SwipeableViews>
             
                    <div>
                        <div className={E("input-wrapper")}>
                            <input className={E("input")} type="text" value={name_} placeholder="Last name, First name Middle name" onChange={nameOnChange}/>
                            <img className={E("input__close", [deleteNameIconAnimation ? "animate" : "animate-out"])} onClick={nameOnClear} src={deleteIcon} alt="close"/>
                        </div>
                    </div>

                    {/* <div>
                        <div className={E("input-wrapper")}>
                            <input className={E("input")} type="text" value={lastName_} placeholder="Last Name" onChange={lastNameOnChange}/>
                            <img className={E("input__close", [deleteLastNameIconAnimation ? "animate" : "animate-out"])} onClick={lastNameOnClear} src={deleteIcon} alt="close"/>
                        </div>
                    </div> */}

                    <div>
                        <Select
                            className={E("input")}
                            native
                            value={age_}
                            onChange={ event => setAge(event.target.value as number)}
                            disableUnderline>
                            {
                            ageOptions.map((age, index) => {
                            return <option key={index} value={age}>{age}</option>
                            })}
                        </Select>
                    </div>

                    <div className={E('button-wrapper')}>
                        <Button 
                        text="Save Changes"
                        type="secondary"
                        outlined={false}
                        click={() => handleSaveChangesOnClick()}
                        /> 
                    </div>
                
                    <div>
                        <Navigation page={routes.profile.id} pageFrom={routes.profile}/>
                    </div>
                </div>


            </ SwipeableViews>

            <Modal show={showSaveModal} popup onClose={() => saveModalOnClose()} closeModalOnOutsideClick={true}>
                <div className={E("modal__save")}>
                    <div>
                        <h1>Changes saved successfully!</h1>
                    </div>
                    <div>
                        <img src={emojiHappy} alt="emoji-happy" />
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default withAnimationTransition(Profile, {
    entrance: 'FADE_IN'
});
