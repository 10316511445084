import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import MobileDistanceInstruction40cm from '../../components/instructions/MobileDistanceInstruction40cm';
import WearYourEyeGlassesInstruction40cm from '../../components/instructions/WearYourEyeGlassesInstruction40cm';
import wearYourEyeglasses from '../../assets/svg/instructions/wear-your-eyeglasses.svg';
import Button from '../../components/ui/Button';
import backIcon from '../../assets/svg/icons/delete-icon.svg';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { getUser } from '../../store/slices/user-slice';
import { useSelector } from 'react-redux';
import CoverRightEyeInstruction from '../../components/instructions/CoverRightEyeInstruction';

interface Props {

}

const ColorVisionTestInstructions: React.FC<Props> = () => {
    // VARIABLES
    const [B] = useBEM(routes.colorvisiontestinstructions.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.colorvisiontestinstructions,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    const goToSelectMode = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.colorvisiontestinstructions,
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToColorVisionTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.colorvisiontestinstructions,
                routes.colorvisiontest,
                'SLIDE_LEFT',
                500
            );
        }, 250);
    }

    return (
        <div className={B() + ' page bg-blue'}>
            <div className="flex-col">
                <div className="flex-row title-wrapper">
                    <img src={backIcon} alt="back-icon" onClick={() => goToSelectMode()} />
                    <h1>Color Vision</h1>
                </div>

                <SwipeableViews enableMouseEvents={true} style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={0}>

                    {/* swipeableindex 0  */}
                    <div className="instructions">
                        <MobileDistanceInstruction40cm />

                        <div className="instructions__swipe">Swipe to proceed</div>
                    </div>

                    {/* swipeableindex 1  */}
                    <div className="instructions">
                        <div className='instruction__image'>
                            <img src={wearYourEyeglasses} alt="mobile-distance-instruction" />
                        </div>

                        <div className='breadcrumb__container'>
                            <div className='breadcrumb'></div>
                            <div className='breadcrumb--selected'></div>
                            <div className='breadcrumb'></div>
                        </div>

                        <div className='instruction__title'>
                            <h2>Wear Your Glasses</h2>
                        </div>

                        <div className='instruction__description'>
                            <caption>If you have glasses for distance vision or progressive lenses, keep them on. If you wear progressive lenses, lift your head in order to look through the lower part of your lenses.</caption>
                        </div>
                    </div>

                    {/* swipeableindex 2  */}
                    <div className="instructions">
                        <CoverRightEyeInstruction />

                        <div className="instructions__button">
                            <Button
                                text="Start Game"
                                type="secondary"
                                outlined={false}
                                click={() => goToColorVisionTest()}
                            />
                        </div>
                    </div>

                </SwipeableViews>
            </div>
        </div>
    )
}

export default withAnimationTransition(ColorVisionTestInstructions, {
    entrance: 'FADE_IN'
});
